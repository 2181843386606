export function OrangeArrowDown() {
    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="8"
      viewBox="0 0 12 8"
      style={{marginTop: '3px'}}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeWidth="1"
      >
        <g stroke="#FF6F02" strokeWidth="1.5" transform="translate(-278 -584)">
          <g transform="translate(111 109)">
            <g transform="translate(39 467)">
              <path d="M129 9L134 14 139 9"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
    )
}