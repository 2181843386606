import React, { useContext, useEffect, useState } from 'react';
import { MainPageContext } from '../../../contexts/MainPageContext'; // Используем данные из контекста
import styles from './CarShowcase.module.css';
import { SmallCarCard } from '../../../entities/SmallCarCard';
import { Button } from "../../../shared/ui/button"
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from 'react-router-dom';

export function CarShowcase() {
    const { mainPageData, loading, error } = useContext(MainPageContext);
    const [carsFull, setCarsFull] = useState([])
    const [cars, setCars] = useState([])
    const navigate = useNavigate()
    



    useEffect(() => {
        
        setCarsFull(mainPageData?.data?.cars || [])
        setCars(mainPageData?.data?.cars.slice(0, 12) || [])}, 
        
    [mainPageData])

    if (error) {
        return <div>{error}</div>;
    }

    console.log('МАШИНЫ')
    console.log(cars)


    console.log('main page cars', cars)
    return (
        <div className={styles.container}>
            <div className={styles.carList}>
                {/* <h1 className={styles.title}>Недавно смотрели 🧐</h1>
                <div className={styles.grid}>
                    {cars.slice(0, 5).map((car, index) => (
                        <SmallCarCard car={car} key={index} />
                    ))}
                </div> */}
            </div>
            <h1 className={styles.title}>Может понравиться 😍</h1>
            <div className={styles.content}>

                {
                    cars.length == 0 && <div className={styles.loading}>
                        <div style={{margin: 'auto'}}>
                            <ClipLoader 
                                color={'grey'}
                                size={50}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>
                    </div>
                }

                {cars.map((car, index) => (
                    <SmallCarCard car={car} key={index} />
                ))}
                <div className={styles.button}>
                {
                    cars.length < 24 && cars.length != 0 ? <Button onClick={() => setCars(carsFull.slice(0, 24))}>Показать больше</Button> :
                    <Button onClick={() => navigate('/search')} >Перейти в поиск</Button>
                }
                </div>
                
                               
            </div>
        </div>
    );
}
