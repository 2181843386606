// src/features/CarFilters/ui/CarFilters.tsx

import React, { useEffect } from "react";
import { Selector } from "../../../shared/ui/Selector";
import { MultiSelect } from "../../../shared/ui/MultiSelect";
import { RangeInput } from "../../../shared/ui/RangeInput";
import searchIcon from '../../../shared/assets/search_glass.svg';
import { SearchButton } from '../../../shared/ui/SearchButton/ui/SearchButton';
import styles from './CarFilters.module.css';
import { Input } from "../../../shared/ui/input/ui/Input";
import { ListOfTypes } from "../../../shared/ui/ListOfTypes/ui/ListOfTypes";
import { Checkbox } from "../../../shared/ui/Checkbox/ui/Checkbox";
import { Button } from "../../../shared/ui/button/ui/Button";
import { OrangeArrowDown } from "../../../shared/ui/Icons/OrangeArrowDown/ui/OrangeArrowDown";
import { useCarFilters } from '../hooks/useCarFilters';
import { CarFiltersProps } from '../model/interfaces';
import { useState } from "react";
import {
  bodyTypes,
  transmissions,
  fuelTypes,
  driveTypes,
  types,
  europeanCountriesAndCities,
  users,
  sellers,
  radius,      // Добавлено
  lastAdded,   // Добавлено
} from "../model/constants";

export function CarFilters({ handleCars }: CarFiltersProps) {
  const {
    modelsList,
    brandsList,
    selectedCars,
    selectedbody,
    selectedTransmission,
    selectedRadius,
    selectGeneration,
    selectEngines,
    selectedLastAdd,
    selectedTypeOfSeller,
    selectedAmountOfOwners,
    dynamicNumber,
    cities,
    selectDrive,
    selectedType,
    years,
    selectedRegions,
    selectedCities,
    handleSelectedBrand,
    handleSelectedModel,
    handleSelectedbody,
    handleSelectedTransmission,
    handleSelectedFuelType,
    handleSelectedDriveType,
    handleSelectedType,
    handleSelectedRegions,
    handleSelectedCities,
    handleSelectedSellers,
    handleMilageFromChange,
    handleMilageToChange,
    handleRadiusChange,
    handlePriceFromChange,
    handlePriceToChange,
    handlePowerFromChange,
    handlePowerToChange,
    handleVolumeFromChange,
    handleVolumeToChange,
    handleSelectedLastAdd,
    handleSelectedUsers,
    handleStartYear,
    handleEndYear,
    checkboxesChange,
    sendData,
    setSelectGeneration,
    resetFilters,
    filters,
    setFiltersDirectly,
    setTempFilters,
    setSelectedCars
  } = useCarFilters(handleCars);

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [name, setName] = useState('')

  useEffect(() => {
    console.log('initial render')
    if (isInitialRender) {
      setIsInitialRender(false);
      return;
    }
    if (loadingFlag) {
      localStorage.setItem('tempFilters', JSON.stringify(filters))
      console.log('AMAMAM')
      console.log(localStorage.getItem('tempFilters'))
    }
    

  }, [filters])


const findKeyByValue = (obj: { [key: string]: string }, value: string): string | undefined => {
  return Object.keys(obj).find(key => obj[key] === value);
};


  useEffect(() => {
    console.log('START')
    console.log(localStorage.getItem('tempFilters'))
      if (localStorage.getItem('tempFilters') != null) {
        console.log("ENENENEN")
        let filters = JSON.parse(localStorage.getItem('tempFilters') || '{}')
        console.log(filters)
        if ('brand' in filters) {
          handleSelectedBrand(0, filters.brand[0] || '');
        }
        if ('model' in filters) {
          handleSelectedModel(0, filters.model[0] || '');
        }
        if ('body' in filters) {
          handleSelectedbody(findKeyByValue(bodyTypes, filters.body) || '');
        }
        if ('transmission' in filters) {
          handleSelectedTransmission(filters.transmission || '');
        }
        if ('fuelTypes' in filters) {
          handleSelectedFuelType(filters.fuelTypes || '');
        }
        if ('driveTypes' in filters) {
          handleSelectedDriveType(filters.driveTypes || '');
        }
        if ('carType' in filters) {
          handleSelectedType(filters.carType || '');
        }
        if ('regions' in filters) {
          handleSelectedRegions(filters.regions || []);
        }
        if ('cities' in filters) {
          handleSelectedCities(filters.cities || []);
        }
        if ('typeOfSeller' in filters) {
          handleSelectedSellers(filters.typeOfSeller || '');
        }
        if ('mileageFrom' in filters) {
          handleMilageFromChange(filters.mileageFrom || undefined);
        }
        if ('mileageTo' in filters) {
          handleMilageToChange(filters.mileageTo || undefined);
        }
        if ('radius' in filters) {
          handleRadiusChange(filters.radius || undefined);
        }
        if ('priceFrom' in filters) {
          handlePriceFromChange(filters.priceFrom || undefined);
        }
        if ('priceTo' in filters) {
          handlePriceToChange(filters.priceTo || undefined);
        }
        if ('powerFrom' in filters) {
          handlePowerFromChange(filters.powerFrom || undefined);
        }
        if ('powerTo' in filters) {
          handlePowerToChange(filters.powerTo || undefined);
        }
        if ('volumeFrom' in filters) {
          handleVolumeFromChange(filters.volumeFrom || undefined);
        }
        if ('volumeTo' in filters) {
          handleVolumeToChange(filters.volumeTo || undefined);
        }
        if ('lastAdd' in filters) {
          handleSelectedLastAdd(filters.lastAdd || '');
        }
        if ('amountOfOwners' in filters) {
          handleSelectedUsers(filters.amountOfOwners || '');
        }
        if ('yearFrom' in filters) {
          handleStartYear(filters.yearFrom || '');
        }
        if ('yearTo' in filters) {
          handleEndYear(filters.yearTo || '');
        }
      }

      setLoadingFlag(true)
      console.log(filters)
   }, [])

   const [savedFilters, setSavedFilters] = useState({})

   function getSavedFilters() {
    let saved = localStorage.getItem('savedFilters');
    let savedFilters: { [key: string]: object } = JSON.parse(saved || '{}'); 
    setSavedFilters(savedFilters)
  }

   useEffect(() => {
    getSavedFilters()
   }, [])


   function saveFilters(name: string, filters: object): void {
    let saved = localStorage.getItem('savedFilters');
    let savedFilters: { [key: string]: object } = JSON.parse(saved || '{}'); 

    if (savedFilters != null) {
        savedFilters[name] = filters; 
    }
    console.log(savedFilters)

    localStorage.setItem('savedFilters', JSON.stringify(savedFilters)); 
    getSavedFilters()
}


    function removeFilters(name: string): void {
      let saved = localStorage.getItem('savedFilters');
      let savedFilters: { [key: string]: object } = JSON.parse(saved || '{}'); 

      if (savedFilters != null && savedFilters.hasOwnProperty(name)) {
          delete savedFilters[name]; 
      }


      localStorage.setItem('savedFilters', JSON.stringify(savedFilters)); 
      getSavedFilters()
    }

    function handleFilters(name: string) {
      console.log('FILTERS')
      console.log(name)
      let filters = JSON.parse(localStorage.getItem('savedFilters') || '{}')[name]
      console.log(filters)


      if ('brand' in filters) {
        handleSelectedBrand(0, filters.brand[0] || '');
      }
      if ('model' in filters) {
        handleSelectedModel(0, filters.model[0] || '');
      }
      if ('body' in filters) {
        handleSelectedbody(filters.body || []);
      }
      if ('transmission' in filters) {
        handleSelectedTransmission(filters.transmission || '');
      }
      if ('fuelTypes' in filters) {
        handleSelectedFuelType(filters.fuelTypes || '');
      }
      if ('driveTypes' in filters) {
        handleSelectedDriveType(filters.driveTypes || '');
      }
      if ('carType' in filters) {
        handleSelectedType(filters.carType || '');
      }
      if ('regions' in filters) {
        handleSelectedRegions(filters.regions || []);
      }
      if ('cities' in filters) {
        handleSelectedCities(filters.cities || []);
      }
      if ('typeOfSeller' in filters) {
        handleSelectedSellers(filters.typeOfSeller || '');
      }
      if ('mileageFrom' in filters) {
        handleMilageFromChange(filters.mileageFrom || undefined);
      }
      if ('mileageTo' in filters) {
        handleMilageToChange(filters.mileageTo || undefined);
      }
      if ('radius' in filters) {
        handleRadiusChange(filters.radius || undefined);
      }
      if ('priceFrom' in filters) {
        handlePriceFromChange(filters.priceFrom || undefined);
      }
      if ('priceTo' in filters) {
        handlePriceToChange(filters.priceTo || undefined);
      }
      if ('powerFrom' in filters) {
        handlePowerFromChange(filters.powerFrom || undefined);
      }
      if ('powerTo' in filters) {
        handlePowerToChange(filters.powerTo || undefined);
      }
      if ('volumeFrom' in filters) {
        handleVolumeFromChange(filters.volumeFrom || undefined);
      }
      if ('volumeTo' in filters) {
        handleVolumeToChange(filters.volumeTo || undefined);
      }
      if ('lastAdd' in filters) {
        handleSelectedLastAdd(filters.lastAdd || '');
      }
      if ('amountOfOwners' in filters) {
        handleSelectedUsers(filters.amountOfOwners || '');
      }
      if ('yearFrom' in filters) {
        handleStartYear(filters.yearFrom || '');
      }
      if ('yearTo' in filters) {
        handleEndYear(filters.yearTo || '');
      }
    
    }

  console.log('CARSSS', selectedCars)

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <div className={styles.row} style={{ marginBottom: '20px' }}>
          <div className={styles.rowElement}>
            <ListOfTypes values={Object.keys(types)} selected={selectedType} onSelect={handleSelectedType} />
          </div>
          <div className={styles.rowElementGap}>
          <Checkbox 
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => checkboxesChange(event.target.checked, 'isAccidentFree')} 
            label="С НДС" 
            htmlFor="nds" 
            id="nds"  // Добавляем id
          />
          <Checkbox 
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => checkboxesChange(event.target.checked, 'isBelowMarket')} 
            label="Ниже рынка" 
            htmlFor="under" 
            id="under"  // Добавляем id
          />
          <Checkbox 
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => checkboxesChange(event.target.checked, 'isVat')} 
            label="Без аварий" 
            htmlFor="crash" 
            id="crash"  // Добавляем id
          />
          </div>

          {/* <div className={styles.rowElementCarSelection}><SearchButton icon={searchIcon} label="Подбор авто" redirectPath="/chat" /></div> */}
        </div>
        {selectedCars.map((car, ind) => (
        <div className={styles.row}>
          {/* Блок для выбора марки */}
          <div className={styles.rowElement} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center', gap: '10px' }}>
              <Selector
                label={'Марка'}
                values={brandsList.length > 0 ? brandsList.map(brand => brand.mark) : []}
                value={car.brand}
                width={'100%'}
                onValue={val => handleSelectedBrand(ind, val)}
                disabled={false}
                canType={true}
                isCars={true}
              />
              {
              selectedCars.length -1 == ind ?
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <div onClick={() => {handleSelectedBrand(selectedCars.length, '')}} style={{ width: '40px', height: '40px', background: '#F0EEEE', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4rem' }}>
              <p style={{ color: '' }}>+</p>

              </div>
              {
                ind != 0 &&
                <div onClick={() => {
                  console.log(selectedCars.indexOf(car), ind);
                  setSelectedCars(prev => 
                    prev.filter((_, index) => {
                      const shouldKeep = index !== ind; // true, если хотим оставить элемент
                      console.log(shouldKeep);
                      return shouldKeep;
                    })
                  );
                }} style={{ minWidth: '40px', height: '40px', background: '#F0EEEE', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4rem' }}>
                            <p style={{ color: '' }}>-</p>
              
                            </div>
                 
              }
              
            </div>
            :
              <div onClick={() => {
                console.log(selectedCars.indexOf(car), ind);
                setSelectedCars(prev => 
                  prev.filter((_, index) => {
                    const shouldKeep = index !== ind; // true, если хотим оставить элемент
                    console.log(shouldKeep);
                    return shouldKeep;
                  })
                );
              }} style={{ minWidth: '40px', height: '40px', background: '#F0EEEE', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '4rem' }}>
                <p style={{ color: '' }}>-</p>

              </div>
            }
            </div>
            
          </div>
          {/* Блок для выбора модели */}
          <div className={styles.rowElement} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
            <Selector
              label={'Модель'}
              values={car.modelsList.map(model => model.model)}
              value={car.model}
              width={'100%'}
              onValue={val => handleSelectedModel(ind, val)}
              disabled={!car.brand} // Поле неактивно, если марка не выбрана
              canType={true}
              isCars={true}
            />
            </div>
           
          </div>

          <div className={styles.rowElementGap} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            {selectGeneration.map((gen, index) => (
              <div key={index} style={{ display: 'flex', width: '100%', justifyContent: 'space-between', gap: '10px', alignItems: 'center' }}>
                <Selector
                  label={'Поколение'}
                  values={[]}  // Здесь можно добавить доступные значения поколений
                  value={gen}
                  width={'100%'}
                  onValue={(car: string) => setSelectGeneration((prev) => {
                    const a = [...prev];
                    a[index] = car;
                    return a;
                  })}
                  isCars={true}
                />
                <Input placeholder={'Прим GTI'} />
              </div>
            ))}
          </div>
        </div>))}
       
        <div className={styles.row} style={{ marginTop: '40px' }}>
          <div className={styles.rowElementGap}>
            <Selector label={'Кузов'} values={Object.keys(bodyTypes)} value={selectedbody} onValue={handleSelectedbody} />
            <Selector label={'Коробка'} values={Object.keys(transmissions)} value={selectedTransmission} onValue={handleSelectedTransmission} />
          </div>

          <div className={styles.rowElementGap}>
            <Selector label={'Двигатель'} values={Object.keys(fuelTypes)} value={selectEngines} onValue={handleSelectedFuelType} />
            <Selector label={'Привод'} values={Object.keys(driveTypes)} value={selectDrive} onValue={handleSelectedDriveType} />
          </div>

          <div className={styles.rowElement}>
            
            <RangeInput
              placeholders={['Объем от, л', 'до']}
              onFromChange={handleVolumeFromChange}
              onToChange={handleVolumeToChange}
            />
          </div>
        </div>

        <div className={styles.row} style={{ marginBottom: '40px', marginTop: '10px' }}>
          <div className={styles.rowElementRangeSelector}>
            <Selector
              label="Год от"
              values={years.filter((year) => filters['yearTo'] === undefined || Number(year) <= Number(filters['yearTo']))}
              onValue={handleStartYear}
              type={'left'}
            />
            <Selector
              label="до"
              values={years.filter((year) => filters['yearFrom'] === undefined || Number(year) >= Number(filters['yearFrom']))}
              onValue={handleEndYear}
              type={'right'}
            />
          </div>
          <div className={styles.rowElement}>

          <RangeInput 
              placeholders={['Пробег от, км', "до"]}
              onFromChange={handleMilageFromChange}
              onToChange={handleMilageToChange}
            />
          </div>

          <div className={styles.rowElement}>
            <RangeInput 
              placeholders={['Мощность', 'до']}
              onFromChange={handlePowerFromChange}
              onToChange={handlePowerToChange}
            />
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.rowElement}>
            <MultiSelect
              values={Object.keys(europeanCountriesAndCities)}
              label="Выберите регионы"
              selected={selectedRegions}
              onSelected={handleSelectedRegions}
            />
          </div>

          <div className={styles.rowElementGap}>
            <Selector withFloatingLabel={true} values={radius} onValue={handleRadiusChange} value={selectedRadius} label={'Радиус, км'} />
            <Selector withFloatingLabel={true} values={Object.keys(lastAdded)} onValue={handleSelectedLastAdd} value={selectedLastAdd} label={'Последнее добавление'} />
          </div>

          <div className={styles.rowElement}>
          <RangeInput
            placeholders={['Цена от', 'до']}
            onFromChange={handlePriceFromChange}
            onToChange={handlePriceToChange}
          />
          </div>
        </div>

        <div className={styles.lastRow} style={{ marginTop: '10px' }}>
          <div className={styles.rowElement}>
            <MultiSelect
              values={cities}
              label="Выберите город"
              selected={selectedCities}
              onSelected={handleSelectedCities}
            />
          </div>

          <div className={styles.rowElementGap}>
            <Selector withFloatingLabel={true} values={Object.keys(sellers)} value={selectedTypeOfSeller} onValue={handleSelectedSellers} label={'Тип продавца'} />
            <Selector withFloatingLabel={true} values={Object.keys(users)} value={selectedAmountOfOwners} onValue={handleSelectedUsers} label={'Кол-во владельцев'} />
          </div>
        </div>

        <div className={styles.rowEnd}>
          <div className={styles.rowElementButtons}>
            <div className={styles.allParametrs}>Все параметры <OrangeArrowDown /></div>
            <div className={styles.delete} onClick={resetFilters} role="button" tabIndex={0}>
              Сбросить <p style={{ fontSize: '22px', marginTop: '3px' }}>&times;</p>
            </div>
          </div>

          <div className={styles.rowElement}>
            <Selector handleName = {setName} removeFilters = {removeFilters} handleSaveFilters = {() => saveFilters(name, filters)} filtersSelector={true} label={'Сохраненные фильтры'} values={Object.keys(savedFilters)} onValue={handleFilters} />
          </div>
          <div className={styles.rowElement}>
            <div>
              {/* Другие элементы */}
            </div>
            <Button 
              onClick={() => sendData()} 
              disabled={dynamicNumber === 0} // Проверка: если dynamicNumber = 0, кнопка заблокирована
            >
              Показать {dynamicNumber} предложений
            </Button>
          </div>
        </div>

      </div>
    </div>
  );
}
