export const countryCodes: Record<string, string> = {
    'Германия': 'de',
    'Франция': 'fr',
    'Италия': 'it',
    'Испания': 'es',
    'Великобритания': 'gb',
    'Польша': 'pl',
    'Нидерланды': 'nl',
    'Чехия': 'cz',
    'Австрия': 'at',
    'Швеция': 'se'
};


export const cityTranslations: Record<string, string> = {
    "Берлин": "Berlin",
    "Гамбург": "Hamburg",
    "Мюнхен": "Munich",
    "Кёльн": "Cologne",
    "Франкфурт": "Frankfurt",
    "Штутгарт": "Stuttgart",
    "Дюссельдорф": "Düsseldorf",
    "Дортмунд": "Dortmund",
    "Эссен": "Essen",
    "Бремен": "Bremen",
    "Париж": "Paris",
    "Марсель": "Marseille",
    "Лион": "Lyon",
    "Тулуза": "Toulouse",
    "Ницца": "Nice",
    "Нант": "Nantes",
    "Страсбург": "Strasbourg",
    "Монпелье": "Montpellier",
    "Бордо": "Bordeaux",
    "Лилль": "Lille",
    "Рим": "Rome",
    "Милан": "Milan",
    "Неаполь": "Naples",
    "Турин": "Turin",
    "Палермо": "Palermo",
    "Генуя": "Genoa",
    "Болонья": "Bologna",
    "Флоренция": "Florence",
    "Венеция": "Venice",
    "Верона": "Verona",
    "Мадрид": "Madrid",
    "Барселона": "Barcelona",
    "Валенсия": "Valencia",
    "Севилья": "Seville",
    "Сарагоса": "Zaragoza",
    "Малага": "Malaga",
    "Мурсия": "Murcia",
    "Пальма-де-Майорка": "Palma de Mallorca",
    "Бильбао": "Bilbao",
    "Аликанте": "Alicante",
    "Лондон": "London",
    "Бирмингем": "Birmingham",
    "Манчестер": "Manchester",
    "Ливерпуль": "Liverpool",
    "Лидс": "Leeds",
    "Шеффилд": "Sheffield",
    "Эдинбург": "Edinburgh",
    "Глазго": "Glasgow",
    "Бристоль": "Bristol",
    "Кардифф": "Cardiff",
    "Варшава": "Warsaw",
    "Краков": "Krakow",
    "Лодзь": "Łódź",
    "Вроцлав": "Wrocław",
    "Познань": "Poznań",
    "Гданьск": "Gdańsk",
    "Щецин": "Szczecin",
    "Люблин": "Lublin",
    "Быдгощ": "Bydgoszcz",
    "Катовице": "Katowice",
    "Амстердам": "Amsterdam",
    "Роттердам": "Rotterdam",
    "Гаага": "The Hague",
    "Утрехт": "Utrecht",
    "Эйндховен": "Eindhoven",
    "Гронинген": "Groningen",
    "Бреда": "Breda",
    "Неймеген": "Nijmegen",
    "Алкмар": "Alkmaar",
    "Маастрихт": "Maastricht",
    "Прага": "Prague",
    "Брно": "Brno",
    "Острава": "Ostrava",
    "Пльзень": "Plzeň",
    "Либерец": "Liberec",
    "Оломоуц": "Olomouc",
    "Ческе-Будеевице": "České Budějovice",
    "Градец-Кралове": "Hradec Králové",
    "Усти-над-Лабем": "Ústí nad Labem",
    "Пардубице": "Pardubice",
    "Вена": "Vienna",
    "Грац": "Graz",
    "Линц": "Linz",
    "Зальцбург": "Salzburg",
    "Инсбрук": "Innsbruck",
    "Клагенфурт": "Klagenfurt",
    "Велс": "Wels",
    "Штайр": "Steyr",
    "Санкт-Пельтен": "Sankt Pölten",
    "Фельдкирх": "Feldkirch",
    "Стокгольм": "Stockholm",
    "Гётеборг": "Gothenburg",
    "Мальмё": "Malmö",
    "Уппсала": "Uppsala",
    "Вестерос": "Västerås",
    "Эребру": "Örebro",
    "Линчёпинг": "Linköping",
    "Хельсингборг": "Helsingborg",
    "Норрчёпинг": "Norrköping",
    "Йёнчёпинг": "Jönköping"
};

export const europeanCountriesAndCities: Record<string, string[]> = {
    "Germany": ["Berlin", "Hamburg", "Munich", "Cologne", "Frankfurt", "Stuttgart", "Düsseldorf", "Dortmund", "Essen", "Bremen"],
    "France": ["Paris", "Marseille", "Lyon", "Toulouse", "Nice", "Nantes", "Strasbourg", "Montpellier", "Bordeaux", "Lille"],
    "Italy": ["Rome", "Milan", "Naples", "Turin", "Palermo", "Genoa", "Bologna", "Florence", "Venice", "Verona"],
    "Spain": ["Madrid", "Barcelona", "Valencia", "Seville", "Zaragoza", "Málaga", "Murcia", "Palma de Mallorca", "Bilbao", "Alicante"],
    "United Kingdom": ["London", "Birmingham", "Manchester", "Liverpool", "Leeds", "Sheffield", "Edinburgh", "Glasgow", "Bristol", "Cardiff"],
    "Poland": ["Warsaw", "Kraków", "Łódź", "Wrocław", "Poznań", "Gdańsk", "Szczecin", "Lublin", "Bydgoszcz", "Katowice"],
    "Netherlands": ["Amsterdam", "Rotterdam", "The Hague", "Utrecht", "Eindhoven", "Groningen", "Breda", "Nijmegen", "Alkmaar", "Maastricht"],
    "Czech Republic": ["Prague", "Brno", "Ostrava", "Plzeň", "Liberec", "Olomouc", "České Budějovice", "Hradec Králové", "Ústí nad Labem", "Pardubice"],
    "Austria": ["Vienna", "Graz", "Linz", "Salzburg", "Innsbruck", "Klagenfurt", "Wels", "Steyr", "Sankt Pölten", "Feldkirch"],
    "Sweden": ["Stockholm", "Gothenburg", "Malmö", "Uppsala", "Västerås", "Örebro", "Linköping", "Helsingborg", "Norrköping", "Jönköping"]
};


export const defaultSelectCities: string[] = ["Берлин", "Гамбург", "Мюнхен", "Кёльн", "Франкфурт", "Штутгарт", "Дюссельдорф", "Дортмунд", "Эссен", "Бремен"]
