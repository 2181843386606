import { Input } from "../../../shared/ui/input"
import { Button } from "../../../shared/ui/button"
import styles from './ChangeUserLoginForm.module.css'
import { useScrollTrigger } from "@mui/material"
import { useState } from "react"
import { sendPostRequest } from "../../../shared/api/lib/requests"

export function ChangeUserLoginInfoForm() {

    const [isClicked, setIsClicked] = useState()
    const [email, setEmail] = useState()
    const [code, setCode] = useState()

    function sendChangedData() {
        const data = sendPostRequest('search/add-email', {
            "email": email,
            "access_token": localStorage.getItem("accessToken"),
        })

        setIsClicked(true)
    }

    function sendCode() {
        const data = sendPostRequest('search/confirm-email', {
        
            "access_token": localStorage.getItem("accessToken"),
            "verify_code": code
            
        })
    }



    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <p className={styles.title}>Здесь вы можете изменить данные от аккаунта</p>
                <Input  placeholder={'Номер телефона'} />
                <Input onChange={(e) => setEmail(e.target.value)} placeholder={'Электронная почта'} />
                <Button width={"50%"} onClick={() => sendChangedData()}>Изменить</Button>
            </div>
    
            {
                isClicked &&
                <div className={styles.content}>
                <p className={styles.text}>Вам на почту был отправлен код подтверждения</p>
                <Input onChange={(e) => sendCode()}  placeholder={'Код подтверждения'} />
                <Button width={"50%"}>Отправить</Button>
            </div>
            }
           
        </div>
    )
}