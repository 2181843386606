import React from 'react';
import styles from './Button.module.css';

interface ButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  width?: string;
  fontSize?: string;
  disabled?: boolean; // Добавляем тип для disabled
}

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  width = '100%',
  fontSize = 'unset',
  disabled = false,
}) => {
  return (
    <button
      onClick={onClick}
      className={`${styles.button} ${disabled ? styles.buttonDisabled : ''}`}
      style={{ width: width, fontSize: fontSize }}
      disabled={disabled} // Отключаем кнопку
    >
      {children}
    </button>
  );
};
