import React from 'react';
import styles from './ListOfTypes.module.css'


interface ListOfTypesProps {
    values: string[];
    selected: string;
    onSelect: (value: string) => void;
}


export const ListOfTypes: React.FC<ListOfTypesProps> = ({values, selected, onSelect}) => {
    return (
        <div className={styles.container}>
            {values.map((value) => <span onClick={() => onSelect(value)} className={selected == value ? styles.selected : styles.default} >{value}</span>)}
        </div>
    )
}
