import React, { useState } from 'react';
import styles from './RangeInput.module.css';

interface RangeInputProps {
  placeholders: string[];
  onFromChange?: (value: number | undefined) => void;
  onToChange?: (value: number | undefined) => void;
}

export function RangeInput({ placeholders, onFromChange = () => {}, onToChange = () => {} }: RangeInputProps) {
  const [fromValue, setFromValue] = useState<number | undefined>();
  const [toValue, setToValue] = useState<number | undefined>();

  const handleFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (!event.target.value || isNaN(value) || value === 0) {
      setFromValue(undefined);
      onFromChange(undefined);
    } else if (value <= (toValue ?? Infinity)) {
      setFromValue(value);
      onFromChange(value);
    }
  };

  const handleToChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    if (!event.target.value || isNaN(value) || value === 0) {
      setToValue(undefined);
      onToChange(undefined);
    } else if (value >= (fromValue ?? -Infinity)) {
      setToValue(value);
      onToChange(value);
    }
  };

  return (
    <div className={styles.container}>
      <input
        type="number"
        onChange={handleFromChange}
        className={styles.inputLeft}
        placeholder={placeholders[0]}
        value={fromValue !== undefined ? fromValue : ''}
      />
      <input
        type="number"
        onChange={handleToChange}
        className={styles.inputRight}
        placeholder={placeholders[1]}
        value={toValue !== undefined ? toValue : ''}
      />
    </div>
  );
}
