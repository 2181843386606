import { sendGetRequest, sendPostRequest } from "../../../shared/api/lib/requests";
import { AvaliableCarsResponse, Filter, CarsCountResponse } from "../model/interfaces"
import { Cars, CarsResponse } from "../../../common/interfaces"
import React from "react";


export const getCarsNames = async (): Promise<AvaliableCarsResponse> => {
    const res: AvaliableCarsResponse = await sendGetRequest<AvaliableCarsResponse>('search/available-cars');
    return res;
};



export const validateFilters = (filter: Filter): Partial<Filter> => {
    // Фильтрация параметров с пустыми строками, нулями или неопределёнными значениями
    console.log("validateFilters", filter)
    const cleanedFilter = Object.fromEntries(
        Object.entries(filter).filter(([key, value]) => {
            if (typeof value === 'number') {
                return value !== 0 && !isNaN(value); // Убираем числовые поля, если значение 0 или NaN
            }
            if (Array.isArray(value)) {
                return value.length > 0; // Убираем массивы, если они пустые
            }
            return value !== '' && value !== undefined && value !== null; // Убираем пустые строки, undefined и null
        })
    );
    console.log("cleanedFilter", cleanedFilter)
    return cleanedFilter;
};


export const getCarsCount = async (filter: Filter): Promise<CarsCountResponse> => {
    // Валидация фильтров
    const cleanedFilter = validateFilters(filter);

    const res: CarsCountResponse = await sendGetRequest<CarsCountResponse>('cars-system/cars-count', cleanedFilter);

    console.log("res getCarsCount", res);
    return res;
};



export const getCars = async (filter: Filter): Promise<Cars[]> => {
    // Валидация фильтров
    console.log("getCars start", filter);

    const cleanedFilter = validateFilters(filter);

    // Проверим, что cleanedFilter — это действительно объект фильтров, а не массив с машинами
    console.log("validateFilters result", cleanedFilter);

    const res: CarsResponse = await sendPostRequest<CarsResponse>('search/search', {data : cleanedFilter});

    const carList: Cars[] = res.data;
    console.log("res carList", carList);

    return carList;
};
