import { CarFiltersForMarket } from "../../../features/CarFiltersForMarket";
import { Sedan } from "../../../shared/ui/Sedan/ui/Sedan";
import styles from './BigCarCard.module.css'

export function BigCarCard({handleRegions, cities, regions, car, selectedCities, carModel, carMark, carGen, handleCities, selectedRegions, handleCars}) {
    return (
        <div className={styles.outer}>        
        <div className={styles.layout}>
            <CarFiltersForMarket car={car} selectedCities={selectedCities} handleCars={handleCars} handleRegions={handleRegions} handleCities={handleCities} cities={cities} regions={regions} selectedRegions={selectedRegions} />
            {
                car && <div className={styles.content}>
                <div className={styles.infoContainer}>
                    <p className={styles.title}>Количество</p>
                    <p className={styles.text}>{car.deactivated_cars_count} шт.</p>
                </div>

                <div className={styles.infoContainer}>
                    <p className={styles.title}>Снято за 3 мес.</p>
                    <p className={styles.text}>{car.deactivated_cars_last_3_months_count} шт.</p>
                </div>

                <div className={styles.infoContainer}>
                    <p className={styles.title}>Средняя цена</p>
                    <p className={styles.text}>{car.average_selling_price}€</p>
                </div>

                <div className={styles.infoContainer}>
                    <p className={styles.title}>Ср. срок продажи</p>
                    <p className={styles.text}>{Math.round(car.average_time_on_market)} дней</p>
                </div>

                <div className={styles.infoContainer}>
                    <p className={styles.title}>Ликвидность</p>
                    <p className={styles.text}>{car.liquidity}%</p>
                </div>

                <div className={styles.infoContainer}>
                    <p className={styles.title}>Ср. пробег</p>
                    <p className={styles.text}>{car.average_mileage_at_selling} км.</p>
                </div>
            </div>
            }
            
            </div>
        </div>
    )
}