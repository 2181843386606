import React, { useContext } from 'react';
import { MainPageContext } from '../../../../contexts/MainPageContext';
import styles from './ListOfBrands.module.css';
import { MainPageContextProps } from '../../../../common/interfaces';
import { useNavigate } from 'react-router-dom';
import { useCarFilters } from '../../../../features/СarFilters/hooks/useCarFilters'; // Подключаем хук для фильтров

interface BrandData {
  mark: string;
  new_cars_count: number;
  used_cars_count: number;
}

interface ListOfBrandsProps {
  selectedType: string;
}

export const ListOfBrands: React.FC<ListOfBrandsProps> = ({ selectedType }) => {
  const { mainPageData, loading, error } = useContext<MainPageContextProps>(MainPageContext);

  if (loading) {
    return <div>Загрузка брендов...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  const brands = (mainPageData?.data?.top_brands || []).slice(0, 25);

  if (!brands.length) {
    return <div>Нет данных для отображения</div>;
  }

  return (
    <div className={styles.carList}>
      {brands.map((brandData, index) => (
        <Brand key={index} brandData={brandData} selectedType={selectedType} />
      ))}
    </div>
  );
};

// Интерфейс для пропсов компонента Brand
interface BrandProps {
  brandData: BrandData;
  selectedType: string;
}

const Brand: React.FC<BrandProps> = ({ brandData, selectedType }) => {
  const { mark, new_cars_count, used_cars_count } = brandData;
  const navigate = useNavigate();
  const { setFiltersDirectly, sendData } = useCarFilters(() => {}); // Получаем функции из хука

  let model_count = 0;
  if (selectedType === "Новые") {
    model_count = new_cars_count;
  } else if (selectedType === "С пробегом") {
    model_count = used_cars_count;
  } else {
    model_count = new_cars_count + used_cars_count;
  }

  // Обработчик клика по бренду
  const handleBrandClick = () => {
    // Устанавливаем фильтр для выбранного бренда перед редиректом
    setFiltersDirectly({ brand: [mark] });

    // Редирект с query-параметром ?brand=<название бренда>
    navigate(`/search?brand=${encodeURIComponent(mark)}`);

    // Запускаем поиск с выбранным брендом
    sendData();
  };

  return (
    <div className={styles.carItem} onClick={handleBrandClick}>
      <span className={styles.carBrand}>{mark}</span>
      <span className={styles.carCount}>{model_count}</span>
    </div>
  );
};
