import { Filter } from '../model/interfaces'; // Убедитесь, что Filter правильно импортирован

const users: Record<string, undefined | number> = {'': undefined, 'До 1': 1, 'До 2': 2, 'До 3': 3, 'До 4': 4, 'До 5': 5, 'До 6': 6, 'До 7': 7, 'До 8': 8, 'До 9': 9}
    
    const brands: string[] = ['BMW', 'Audi', 'Mercedes', 'Volkswagen']
    const lastAdded: Record<string, number> = {'Сегодня': 0, 'Три дня назад': 3, 'Неделю назад': 7}

    const bodyTypes: Record<string, string> = {
      "Фургон для доставки (короткий)": "boxtypedeliveryvan",
      "Кабриолет": "cabrio",
      "Клубный автобус": "clubbus",
      "Универсал": "estatecar",
      "Минивэн (до 9 мест)": "estateminibusupto9seatsvan",
      "Фургон для доставки (высокий и длинный)": "highandlongboxtypedeliveryvan",
      "Фургон для доставки (высокий)": "highboxtypedeliveryvan",
      "Лимузин": "limousine",
      "Фургон для доставки (длинный)": "longboxtypedeliveryvan",
      "Автодома и прочее": "motorhomesandother",
      "Внедорожник": "offroad",
      "Другие автобусы": "otherbus",
      "Другие автомобили": "othercar",
      "Прочие фургоны (до 7500 кг)": "othervanupto7500",
      "Маленький автомобиль": "smallcar",
      "Спортивный автомобиль": "sportscar",
      "Грузовик с платформой": "stakebodyvan",
      "Фургон": "van",
      "Автодом (фургон)": "vanmotorhome"
  };
  
    
    const transmissions: Record<string, string> = {
      "Автоматическая": "automatic",
      "Механическая": "manual",
      "Полуавтоматическая": "semi-automatic",
      "Вариатор": "CVT"
    };
    
    const fuelTypes: Record<string, string> = {
      "Бензин": "gasoline",
      "Дизель": "diesel",
      "Электричество": "electric",
      "Гибрид": "hybrid",
      "Водород": "hydrogen",
      "Природный газ": "natural gas"
    };
    
    const driveTypes: Record<string, string> = {
      "Передний привод": "front-wheel",
      "Задний привод": "rear-wheel",
      "Полный привод": "all-wheel",
      "Четырехколесный привод": "four-wheel"
    };
     

    const radius: string[] = ["10", "20", "30"]
    const types: Record<string, string> = {'Все': 'all', 'Новые': 'new', 'С пробегом': 'with mileage'}

    const cityTranslations: Record<string, string> = {
      "Берлин": "Berlin",
      "Гамбург": "Hamburg",
      "Мюнхен": "Munich",
      "Кёльн": "Cologne",
      "Франкфурт": "Frankfurt",
      "Штутгарт": "Stuttgart",
      "Дюссельдорф": "Düsseldorf",
      "Дортмунд": "Dortmund",
      "Эссен": "Essen",
      "Бремен": "Bremen",
      "Париж": "Paris",
      "Марсель": "Marseille",
      "Лион": "Lyon",
      "Тулуза": "Toulouse",
      "Ницца": "Nice",
      "Нант": "Nantes",
      "Страсбург": "Strasbourg",
      "Монпелье": "Montpellier",
      "Бордо": "Bordeaux",
      "Лилль": "Lille",
      "Рим": "Rome",
      "Милан": "Milan",
      "Неаполь": "Naples",
      "Турин": "Turin",
      "Палермо": "Palermo",
      "Генуя": "Genoa",
      "Болонья": "Bologna",
      "Флоренция": "Florence",
      "Венеция": "Venice",
      "Верона": "Verona",
      "Мадрид": "Madrid",
      "Барселона": "Barcelona",
      "Валенсия": "Valencia",
      "Севилья": "Seville",
      "Сарагоса": "Zaragoza",
      "Малага": "Malaga",
      "Мурсия": "Murcia",
      "Пальма-де-Майорка": "Palma de Mallorca",
      "Бильбао": "Bilbao",
      "Аликанте": "Alicante",
      "Лондон": "London",
      "Бирмингем": "Birmingham",
      "Манчестер": "Manchester",
      "Ливерпуль": "Liverpool",
      "Лидс": "Leeds",
      "Шеффилд": "Sheffield",
      "Эдинбург": "Edinburgh",
      "Глазго": "Glasgow",
      "Бристоль": "Bristol",
      "Кардифф": "Cardiff",
      "Варшава": "Warsaw",
      "Краков": "Krakow",
      "Лодзь": "Łódź",
      "Вроцлав": "Wrocław",
      "Познань": "Poznań",
      "Гданьск": "Gdańsk",
      "Щецин": "Szczecin",
      "Люблин": "Lublin",
      "Быдгощ": "Bydgoszcz",
      "Катовице": "Katowice",
      "Амстердам": "Amsterdam",
      "Роттердам": "Rotterdam",
      "Гаага": "The Hague",
      "Утрехт": "Utrecht",
      "Эйндховен": "Eindhoven",
      "Гронинген": "Groningen",
      "Бреда": "Breda",
      "Неймеген": "Nijmegen",
      "Алкмар": "Alkmaar",
      "Маастрихт": "Maastricht",
      "Прага": "Prague",
      "Брно": "Brno",
      "Острава": "Ostrava",
      "Пльзень": "Plzeň",
      "Либерец": "Liberec",
      "Оломоуц": "Olomouc",
      "Ческе-Будеевице": "České Budějovice",
      "Градец-Кралове": "Hradec Králové",
      "Усти-над-Лабем": "Ústí nad Labem",
      "Пардубице": "Pardubice",
      "Вена": "Vienna",
      "Грац": "Graz",
      "Линц": "Linz",
      "Зальцбург": "Salzburg",
      "Инсбрук": "Innsbruck",
      "Клагенфурт": "Klagenfurt",
      "Велс": "Wels",
      "Штайр": "Steyr",
      "Санкт-Пельтен": "Sankt Pölten",
      "Фельдкирх": "Feldkirch",
      "Стокгольм": "Stockholm",
      "Гётеборг": "Gothenburg",
      "Мальмё": "Malmö",
      "Уппсала": "Uppsala",
      "Вестерос": "Västerås",
      "Эребру": "Örebro",
      "Линчёпинг": "Linköping",
      "Хельсингборг": "Helsingborg",
      "Норрчёпинг": "Norrköping",
      "Йёнчёпинг": "Jönköping"
    };
    

    const europeanCountriesAndCities: Record<string, string[]> = {
      "Германия": ["Берлин", "Гамбург", "Мюнхен", "Кёльн", "Франкфурт", "Штутгарт", "Дюссельдорф", "Дортмунд", "Эссен", "Бремен"],
      "Франция": ["Париж", "Марсель", "Лион", "Тулуза", "Ницца", "Нант", "Страсбург", "Монпелье", "Бордо", "Лилль"],
      "Италия": ["Рим", "Милан", "Неаполь", "Турин", "Палермо", "Генуя", "Болонья", "Флоренция", "Венеция", "Верона"],
      "Испания": ["Мадрид", "Барселона", "Валенсия", "Севилья", "Сарагоса", "Малага", "Мурсия", "Пальма-де-Майорка", "Бильбао", "Аликанте"],
      "Великобритания": ["Лондон", "Бирмингем", "Манчестер", "Ливерпуль", "Лидс", "Шеффилд", "Эдинбург", "Глазго", "Бристоль", "Кардифф"],
      "Польша": ["Варшава", "Краков", "Лодзь", "Вроцлав", "Познань", "Гданьск", "Щецин", "Люблин", "Быдгощ", "Катовице"],
      "Нидерланды": ["Амстердам", "Роттердам", "Гаага", "Утрехт", "Эйндховен", "Гронинген", "Бреда", "Неймеген", "Алкмар", "Маастрихт"],
      "Чехия": ["Прага", "Брно", "Острава", "Пльзень", "Либерец", "Оломоуц", "Ческе-Будеевице", "Градец-Кралове", "Усти-над-Лабем", "Пардубице"],
      "Австрия": ["Вена", "Грац", "Линц", "Зальцбург", "Инсбрук", "Клагенфурт", "Велс", "Штайр", "Санкт-Пельтен", "Фельдкирх"],
      "Швеция": ["Стокгольм", "Гётеборг", "Мальмё", "Уппсала", "Вестерос", "Эребру", "Линчёпинг", "Хельсингборг", "Норрчёпинг", "Йёнчёпинг"]
    };

    const countryCodes: Record<string, string> = {
      'Германия': 'de',
      'Франция': 'fr',
      'Италия': 'it',
      'Испания': 'es',
      'Великобритания': 'gb',
      'Польша': 'pl',
      'Нидерланды': 'nl',
      'Чехия': 'cz',
      'Австрия': 'at',
      'Швеция': 'se'
    };
    const countryCodesReversed: Record<string, string> = {

        'de': 'Германия',
        'fr': 'Франция',
        'it': 'Италия',
        'es': 'Испания',
        'gb': 'Великобритания',
        'pl': 'Польша',
        'nl': 'Нидерланды',
        'cz': 'Чехия',
        'at': 'Австрия',
        'se': 'Швеция'

    };

    const sellers: Record<string, string> = {'Частные': 'private', 'Диллеры': 'dealer'}

    const defaultFilters: Filter = {
      brand: [],
      model: [],
      carType: 'all',
    
      isAccidentFree: false,
      isBelowMarket: false,
      isVat: false,
    
      body: [],
      transmission: '',
      radius: 0,
      generation: '',
      engines: '',
      lastAdd: 0,
      typeOfSeller: '',
      amountOfOwners: 0,
      fuelTypes: '',
      driveTypes: '',
    
      mileageFrom: 0,
      mileageTo: 0,
    
      volumeFrom: 0,
      volumeTo: 0,
    
      powerFrom: 0,
      powerTo: 0,
    
      yearFrom: 0,
      yearTo: 0,
    
      priceFrom: 0,
      priceTo: 0,
    
      regions: [],
      cities: [],
    };
    

export {
  users, 
  brands, 
  lastAdded, 
  bodyTypes, 
  transmissions, 
  fuelTypes, 
  driveTypes, 
  radius, 
  types, 
  cityTranslations, 
  countryCodes, 
  europeanCountriesAndCities, 
  sellers,
  defaultFilters,
  countryCodesReversed

}
