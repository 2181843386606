
// src/features/CarFilters/hooks/useCarFilters.ts

import { useLocation } from "react-router-dom";  // Добавляем импорт useLocation
import { useState, useEffect, useRef } from 'react';
import { Brand, Model, Filter, CarFiltersProps } from '../model/interfaces';
import { getBrandsList, getModelsList } from '../lib/functions';
import { getCars, getCarsCount } from '../lib/request';
import {
  bodyTypes,
  transmissions,
  fuelTypes,
  driveTypes,
  types,
  europeanCountriesAndCities,
  countryCodes,
  cityTranslations,
  users,
  sellers,
  lastAdded,
  countryCodesReversed
} from '../model/constants';
import {defaultFilters} from "../model/constants"

type Car = {
  brand : string,
  model : string,
  modelsList : Model[]
}

export function useCarFilters(handleCars: CarFiltersProps['handleCars']) {
  const prevSelectedState = JSON.parse(localStorage.getItem("userSelectedFilters") as string) as any || {}
  // Состояния
  const [modelsList, setModelsList] = useState<Model[]>([]);
  const [brandsList, setBrandsList] = useState<Brand[]>([]);

  const [selectedCars, setSelectedCars] = useState<Car[]>(prevSelectedState["selectedCars"] || []); // Одно выбранное значение

  // const [selectedBrand, setSelectedBrand] = useState<string>(prevSelectedState["selectedBrand"] || ''); // Одно выбранное значение
  // const [selectedModel, setSelectedModel] = useState<string>(prevSelectedState["selectedModel"] || '');

  const [selectedbody, setSelectedbody] = useState<string | undefined>();
  const [selectedTransmission, setSelectedTransmission] = useState<string | undefined>();
  const [selectedRadius, setSelectedRadius] = useState<string | undefined>('10');

  const [selectGeneration, setSelectGeneration] = useState<string[]>(['']);
  const [selectEngines, setSelectEngines] = useState<string | undefined>();
  const [selectedLastAdd, setSelectedLastAdd] = useState<string>('Три дня назад');
  const [selectedTypeOfSeller, setSelectedTypeOfSeller] = useState<string>('');
  const [selectedAmountOfOwners, setSelectedAmountOfOwners] = useState<string>('До 2');

  const [mileageFrom, setMilageFrom] = useState<number | undefined>();
  const [mileageTo, setMilageTo] = useState<number | undefined>();

  const [volumeFrom, setVolumeFrom] = useState<number | undefined>();
  const [volumeTo, setVolumeTo] = useState<number | undefined>();

  const [powerFrom, setPowerFrom] = useState<number | undefined>();
  const [powerTo, setPowerTo] = useState<number | undefined>();

  const [priceFrom, setPriceFrom] = useState<number | undefined>();
  const [priceTo, setPriceTo] = useState<number | undefined>();

  const [carCondition] = useState<string>('Все');

  const [dynamicNumber, setDynamicNumber] = useState<number | string>('+100000');

  const [cities, setCities] = useState<string[]>([
    "Берлин", "Гамбург", "Мюнхен", "Кёльн", "Франкфурт", "Штутгарт", "Дюссельдорф", "Дортмунд", "Эссен", "Бремен",
    "Париж", "Марсель", "Лион", "Тулуза", "Ницца", "Нант", "Страсбург", "Монпелье", "Бордо", "Лилль"
  ]);

  const [selectDrive, setSelectDrive] = useState<string | undefined>();
  const [startYear, setStartYear] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('Все');

  const [endYear, setEndYear] = useState<string>('');
  const years = Array.from({ length: 100 }, (_, i) => (2024 - i).toString());

  const [selectedRegions, setSelectedRegions] = useState<string[]>(["Германия", "Франция"]);
  const [selectedCities, setSelectedCities] = useState<string[]>([
    "Берлин", "Гамбург", "Мюнхен", "Кёльн", "Франкфурт", "Штутгарт", "Дюссельдорф", "Дортмунд", "Эссен", "Бремен",
    "Париж", "Марсель", "Лион", "Тулуза", "Ницца", "Нант", "Страсбург", "Монпелье", "Бордо", "Лилль"
  ]);

  const location = useLocation(); // Получаем текущий URL
  const [isUrlHandled, setIsUrlHandled] = useState(false); // Флаг для управления состоянием

  // Состояние для фильтров
  const [filters, setFilters] = useState<Filter>(defaultFilters);

  function setFiltersDirectly(newFilters: Partial<Filter>) {
    setFilters(prevFilters => ({
      ...prevFilters,
      ...newFilters
    }));
  }

  const handleFilterChange = (key: keyof Filter, value: any) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [key]: value
    }));
  };

  
  // Функции для подсчёта количества машин
  const findNumberInBrands = (brandsList: Brand[], selectBrand: string): number => {
    if (selectBrand) {
      const brand = brandsList.find(brand => brand.mark === selectBrand);
      return brand ? brand.totalCount : 0;
    }
    return 100000; // Возвращаем общее количество при отсутствии выбранной марки
  };

  const findCountByModels = (modelsList: Model[], selectModel: string, selectBrand: string): number => {
    if (selectModel) {
      const model = modelsList.find(model => model.model === selectModel);
      return model ? model.totalCount : 0;
    }
    return findNumberInBrands(brandsList, selectBrand); // Если модель не выбрана, возвращаем количество для марки
  };


// Реф для хранения предыдущего состояния filters
const prevFiltersRef = useRef<Filter | null>(null);

const timeoutRef = useRef<NodeJS.Timeout | null>(null);

useEffect(() => {
  const prevFilters = prevFiltersRef.current;

  // Проверяем, изменились ли фильтры, кроме brand и model
  const otherFiltersChanged = (() => {
    if (prevFilters) {
      return (Object.keys(filters) as (keyof Filter)[]).some((key) => {
        return key !== 'brand' && key !== 'model' && filters[key] !== prevFilters[key];
      });
    }
    return false;
  })();

  // Отправляем запрос только если изменились другие фильтры или нет brand/model
  const fetchCount = async () => {
    try {
      const response = await getCarsCount(filters);

      // Проверяем, изменилось ли значение dynamicNumber
      if (response.data.count !== dynamicNumber) {
        setDynamicNumber(response.data.count);  // Обновляем только если значение изменилось
        console.log("Dynamic number updated from server:", response.data.count);
      }
    } catch (error) {
      console.error('Ошибка при получении количества автомобилей:', error);
    }
  };

  // if (otherFiltersChanged || (!selectedBrand && !selectedModel)) {
  //   // Очищаем предыдущий таймаут, если есть
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }

  //   // Дебаунс запросов (например, 500 мс)
  //   timeoutRef.current = setTimeout(fetchCount, 500);
  // } else if (selectedBrand || selectedModel) {
  //   const number = selectedBrand && selectedModel
  //     ? findCountByModels(modelsList, selectedModel)
  //     : findNumberInBrands(brandsList, selectedBrand);

  //   // Проверяем, изменилось ли значение dynamicNumber
  //   if (number !== dynamicNumber) {
  //     setDynamicNumber(number);  // Обновляем только если значение изменилось
  //     console.log("Setting dynamic number locally for brand/model change:", number);
  //   }
  // }
  if (otherFiltersChanged || selectedCars.length === 0) {
    // Очищаем предыдущий таймаут, если есть
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Дебаунс запросов (например, 500 мс)
    timeoutRef.current = setTimeout(fetchCount, 500);
  } else if (selectedCars.length !== 0) {
    let number = 0;
    for(const car of selectedCars) {
      if(!car.brand)continue
      number += car.model ? findCountByModels(modelsList, car.model, car.brand) : findNumberInBrands(brandsList, car.brand);
    }
    // Проверяем, изменилось ли значение dynamicNumber
    if (number !== dynamicNumber) {
      setDynamicNumber(number);  // Обновляем только если значение изменилось
      console.log("Setting dynamic number locally for brand/model change:", number);
    }
  }
  // Сохраняем фильтры в localStorage
  const filtersToSave = {
    selectedCars,
    selectedCarBody: filters.body,
    selectedTransmission: filters.transmission,
    selectEngines: filters.fuelTypes,
    selectDrive: filters.driveTypes,
    selectedType: filters.carType,
    selectedRegions: filters.regions,
    selectedCities: filters.cities,
    selectedRadius: filters.radius,
    selectedLastAdd: filters.lastAdd,
    selectedTypeOfSeller: filters.typeOfSeller,
    selectedAmountOfOwners: filters.amountOfOwners,
    startYear: filters.yearFrom,
    endYear: filters.yearTo,
    mileageFrom: filters.mileageFrom,
    mileageTo: filters.mileageTo,
    priceFrom: filters.priceFrom,
    priceTo: filters.priceTo,
    powerFrom: filters.powerFrom,
    powerTo: filters.powerTo,
    volumeFrom: filters.volumeFrom,
    volumeTo: filters.volumeTo,
  };
  localStorage.setItem('userSelectedFilters', JSON.stringify(filtersToSave));

  // Обновляем предыдущие фильтры
  prevFiltersRef.current = filters;

  return () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };
}, [filters, selectedCars]);  // убираем лишние зависимости


  useEffect(() => {
    async function fetchBrands() {
      console.log("Получение списка марок с carCondition", carCondition);
      const brands = await getBrandsList({ carCondition: carCondition });
      brands.sort(function(a, b){
        return a.mark.toLowerCase() < b.mark.toLowerCase() ? -1 : 1;
      });
      setBrandsList(brands);
    }
    fetchBrands();
  }, [carCondition]);


  useEffect(() => {
    async function fetchModels() {
      console.log("Обновление списка моделей");
      let cur_brandsList : string[]= []
      let cur_modelsList : string[] = []
      selectedCars.forEach(async function (car, i) {
        // setSelectedModel(''); // Обнуляем выбранную модель при изменении бренда
        const models = await getModelsList({ mark: car.brand, carCondition: carCondition });
        if(car.brand)
        cur_brandsList.push(car.brand)
        if(car.model)
        cur_brandsList.push(car.model)
        console.log(cur_brandsList)
        console.log(cur_modelsList)
        setSelectedCars(prev => {
          prev[i].modelsList = models
          return prev
        })
        // setModelsList(models);

      });
      // else {
      //   if (modelsList.length > 0) {
      //     console.log("Марка не выбрана, список моделей очищен");
      //     setModelsList([]);
      //   }
      // }

      handleFilterChange('brand', cur_brandsList);
      handleFilterChange('model', cur_brandsList);
    }
    fetchModels();
  }, [selectedCars, carCondition]);
  


  useEffect(() => {
    console.log("Dynamic number updated:", dynamicNumber);
  }, [dynamicNumber]);


  useEffect(() => {
    if (!isUrlHandled) {
      const searchParams = new URLSearchParams(location.search);
      const selectedBrandFromUrl = searchParams.get('brand');
      // Проверяем, изменился ли параметр brand
      if (selectedBrandFromUrl && brandsList.some(brand => brand.mark === selectedBrandFromUrl)) {
        handleSelectedBrand(0, selectedBrandFromUrl);
        setIsUrlHandled(true); // Устанавливаем флаг, чтобы эффект больше не срабатывал
      }
    }
  }, [location.search, brandsList, isUrlHandled]);
  
  

// Обработчик изменений бренда через UI (например, выбор через выпадающий список)
// const handleBrandChange = (brand: string) => {
//   if (brand !== selectedBrand) {
//     handleSelectedBrand(brand);
//     setIsUrlHandled(true);
//   }
// }


  // Обработчики для обновления состояния и фильтров
  function handleSelectedBrand(car_index : number, brand: string) {
    let cur_brandsList :string[] = []
    setSelectedCars(prev => {
      async function lol() {
        const models = await getModelsList({ mark: brand, carCondition: carCondition });
        prev[car_index].brand = brand
        prev[car_index].modelsList = models
      }
      if (prev.length <= car_index)prev.push({brand : '', model : '', modelsList : []})
      lol()
      prev.forEach(xd =>
      cur_brandsList.push(xd.brand))
      return prev
    })
    handleFilterChange('brand', cur_brandsList);
  }

  function handleSelectedModel(car_index : number, model: string) {
    let cur_modelsList :string[] = []
    setSelectedCars(prev => {
      if (prev.length == 0)prev.push({brand : '', model : '', modelsList : []})
      prev[car_index].model = model
      prev.forEach(xd =>
        cur_modelsList.push(xd.model))
      return prev
    })
    handleFilterChange('model', cur_modelsList);
  }

  function handleSelectedbody(body: string) {
    setSelectedbody(body);
    handleFilterChange('body', body ? [bodyTypes[body]] : []);
  }

  function handleSelectedTransmission(transmission: string) {
    setSelectedTransmission(transmission);
    handleFilterChange('transmission', transmissions[transmission]);
  }

  function handleSelectedFuelType(fuelType: string) {
    setSelectEngines(fuelType);
    handleFilterChange('fuelTypes', fuelTypes[fuelType]);
  }

  function handleSelectedDriveType(driveType: string) {
    setSelectDrive(driveType);
    handleFilterChange('driveTypes', driveTypes[driveType]);
  }

  function handleSelectedType(type: string) {
    setSelectedType(type);
    handleFilterChange('carType', types[type]);
  }

  function handleSelectedRegions(value: string[]) {

    

    let reg = value.map(val => countryCodesReversed[val])
    let citiesNew: string[] = [];
    let regNew: string[] = [];

    console.log(reg, citiesNew, regNew)

    for (let i = 0; i < reg.length; i++) {
      citiesNew.push(...europeanCountriesAndCities[reg[i]]);
      regNew.push(countryCodes[reg[i]]);
    }

    let uniqueArray = Array.from(new Set(citiesNew));

    setSelectedRegions(reg);

    handleFilterChange('regions', regNew);

    setCities(uniqueArray);
    if (value.length === 0) {
      setSelectedCities(['Не выбрано']);
    }
  }

  function handleSelectedCities(value: string[]) {
    let citiesNew: string[] = [];

    for (let i = 0; i < value.length; i++) {
      citiesNew.push(cityTranslations[value[i]]);
    }
    setSelectedCities(value);
    handleFilterChange('cities', citiesNew);
  }

  function handleSelectedSellers(value: string) {
    setSelectedTypeOfSeller(value);
    handleFilterChange('typeOfSeller', sellers[value]);
  }
  
  function handleMilageFromChange(value: number | undefined) {
    setMilageFrom(value);
    handleFilterChange('mileageFrom', value);
  }

  function handleMilageToChange(value: number | undefined) {
    setMilageTo(value);
    handleFilterChange('mileageTo', value);
  }

  function handleRadiusChange(value: string | undefined) {
    setSelectedRadius(value);
    handleFilterChange('radius', value);
  }


  function handlePriceFromChange(value: number | undefined) {
    setPriceFrom(value);
    handleFilterChange('priceFrom', value);
  }

  function handlePriceToChange(value: number | undefined) {
    setPriceTo(value);
    handleFilterChange('priceTo', value);
  }


  function handlePowerFromChange(value: number | undefined) {
    setPowerFrom(value);
    handleFilterChange('powerFrom', value);
  }

  function handlePowerToChange(value: number | undefined) {
    setPowerTo(value);
    handleFilterChange('powerTo', value);
  }


  function handleVolumeFromChange(value: number | undefined) {
    setVolumeFrom(value);
    handleFilterChange('volumeFrom', value);
  }

  function handleVolumeToChange(value: number | undefined) {
    setVolumeTo(value);
    handleFilterChange('volumeTo', value);
  }


  function handleSelectedLastAdd(value: string) {
    setSelectedLastAdd(value);
    handleFilterChange('lastAdd', lastAdded[value]);
  }

  function handleSelectedUsers(value: string) {
    setSelectedAmountOfOwners(value);
    handleFilterChange('amountOfOwners', users[value]);
  }

  function handleStartYear(value: string) {

    setStartYear(value);
    handleFilterChange('yearFrom', Number(value));
  }

  function handleEndYear(value: string) {
    setEndYear(value);
    handleFilterChange('yearTo', Number(value));
  }

  function checkboxesChange(status: boolean, type: string) {
    handleFilterChange(type as keyof Filter, status);
  }

  async function sendData() {
    const filtersToSend = { ...filters };
    try {
      console.log("YTREWQ filters", filters);
      const cars = await getCars(filtersToSend); // Предполагается, что getCars возвращает объект с ключом cars
      const carList = cars || []; // Проверка на наличие массива автомобилей
      console.log("const carList", carList);
      handleCars(carList);
      console.log("Автомобили успешно обновлены:", carList);
    } catch (error) {
      console.error("Ошибка при получении автомобилей:", error);
      handleCars([]); // В случае ошибки сбрасываем список автомобилей
    }
  }

  async function setTempFilters(filters: any) {
      console.log('START')
      if ('brand' in filters) {
        handleSelectedBrand(0, filters.brand[0] || '');
      }
      if ('model' in filters) {
        handleSelectedModel(0, filters.model[0] || '');
      }
      if ('body' in filters) {
        handleSelectedbody(filters.body || []);
      }
      if ('transmission' in filters) {
        handleSelectedTransmission(filters.transmission || '');
      }
      if ('fuelTypes' in filters) {
        handleSelectedFuelType(filters.fuelTypes || '');
      }
      if ('driveTypes' in filters) {
        handleSelectedDriveType(filters.driveTypes || '');
      }
      if ('carType' in filters) {
        handleSelectedType(filters.carType || '');
      }
      if ('regions' in filters) {
        handleSelectedRegions(filters.regions || []);
      }
      if ('cities' in filters) {
        handleSelectedCities(filters.cities || []);
      }
      if ('typeOfSeller' in filters) {
        handleSelectedSellers(filters.typeOfSeller || '');
      }
      if ('mileageFrom' in filters) {
        handleMilageFromChange(filters.mileageFrom || undefined);
      }
      if ('mileageTo' in filters) {
        handleMilageToChange(filters.mileageTo || undefined);
      }
      if ('radius' in filters) {
        handleRadiusChange(filters.radius || undefined);
      }
      if ('priceFrom' in filters) {
        handlePriceFromChange(filters.priceFrom || undefined);
      }
      if ('priceTo' in filters) {
        handlePriceToChange(filters.priceTo || undefined);
      }
      if ('powerFrom' in filters) {
        handlePowerFromChange(filters.powerFrom || undefined);
      }
      if ('powerTo' in filters) {
        handlePowerToChange(filters.powerTo || undefined);
      }
      if ('volumeFrom' in filters) {
        handleVolumeFromChange(filters.volumeFrom || undefined);
      }
      if ('volumeTo' in filters) {
        handleVolumeToChange(filters.volumeTo || undefined);
      }
      if ('lastAdd' in filters) {
        handleSelectedLastAdd(filters.lastAdd || '');
      }
      if ('amountOfOwners' in filters) {
        handleSelectedUsers(filters.amountOfOwners || '');
      }
      if ('yearFrom' in filters) {
        handleStartYear(filters.yearFrom || '');
      }
      if ('yearTo' in filters) {
        handleEndYear(filters.yearTo || '');
      }
   
  }

  async function resetFilters() {
      // Сбросить состояния фильтров до undefined
      setSelectedCars([])
      setSelectedbody(undefined);
      setSelectedTransmission(undefined);
      setSelectEngines(undefined);
      setSelectDrive(undefined);
      setSelectedType('');
      setSelectedRegions([]);
      setSelectedCities([]);
      setSelectedTypeOfSeller('');
      setMilageFrom(undefined);
      setMilageTo(undefined);
      setSelectedRadius(undefined);
      setPriceFrom(undefined);
      setPriceTo(undefined);
      setPowerFrom(undefined);
      setPowerTo(undefined);
      setVolumeFrom(undefined);
      setVolumeTo(undefined);
      setSelectedLastAdd('');
      setSelectedAmountOfOwners('');
      setStartYear('');
      setEndYear('');
    
      // Сбросить фильтры в системе (например, отправка фильтров на сервер)
      handleFilterChange('brand', undefined);
      handleFilterChange('model', undefined);
      handleFilterChange('body', []);
      handleFilterChange('transmission', undefined);
      handleFilterChange('fuelTypes', undefined);
      handleFilterChange('driveTypes', undefined);
      handleFilterChange('carType', undefined);
      handleFilterChange('regions', []);
      handleFilterChange('cities', []);
      handleFilterChange('typeOfSeller', undefined);
      handleFilterChange('mileageFrom', undefined);
      handleFilterChange('mileageTo', undefined);
      handleFilterChange('radius', undefined);
      handleFilterChange('priceFrom', undefined);
      handleFilterChange('priceTo', undefined);
      handleFilterChange('powerFrom', undefined);
      handleFilterChange('powerTo', undefined);
      handleFilterChange('volumeFrom', undefined);
      handleFilterChange('volumeTo', undefined);
      handleFilterChange('lastAdd', undefined);
      handleFilterChange('amountOfOwners', undefined);
      handleFilterChange('yearFrom', undefined);
      handleFilterChange('yearTo', undefined);
    
  };

  return {
    modelsList,
    brandsList,
    selectedCars,
    selectedbody,
    selectedTransmission,
    selectedRadius,
    selectGeneration,
    selectEngines,
    selectedLastAdd,
    selectedTypeOfSeller,
    selectedAmountOfOwners,
    mileageFrom,
    mileageTo,
    volumeFrom,
    volumeTo,
    powerFrom,
    powerTo,
    priceFrom,
    priceTo,
    carCondition,
    dynamicNumber,
    cities,
    selectDrive,
    startYear,
    selectedType,
    endYear,
    years,
    selectedRegions,
    selectedCities,
    filters,
    handleSelectedBrand,
    handleSelectedModel,
    handleSelectedbody,
    handleSelectedTransmission,
    handleSelectedFuelType,
    handleSelectedDriveType,
    handleSelectedType,
    handleSelectedRegions,
    handleSelectedCities,
    handleSelectedSellers,
    handleMilageFromChange,
    handleMilageToChange,
    handleRadiusChange,
    handlePriceFromChange,
    handlePriceToChange,
    handlePowerFromChange,
    handlePowerToChange,
    handleVolumeFromChange,
    handleVolumeToChange,
    handleSelectedLastAdd,
    handleSelectedUsers,
    handleStartYear,
    handleEndYear,
    checkboxesChange,
    sendData,
    setSelectGeneration,
    resetFilters,    
    setFiltersDirectly,
    setFilters,
    setTempFilters,
    setSelectedCars
  };
}
