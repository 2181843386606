import React, { useState, useRef, useEffect } from 'react';
import styles from './MultiSelect.module.css';
import { ArrowDown } from '../../Icons/ArrowDown';

interface MultiSelectProps {
  label: string; // Строка для метки
  values: string[]; // Массив строк для вариантов выбора
  selected: string[]; // Массив строк для выбранных значений
  onSelected: (selected: string[]) => void; // Функция обратного вызова для передачи выбранных значений
}

export const MultiSelect: React.FC<MultiSelectProps> = ({ label, values, selected, onSelected }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const dropdownRef = useRef<HTMLDivElement>(null);

  // Обновляем локальное состояние при изменении пропсов selected
  useEffect(() => {
    setSelectedValues(selected);
  }, [selected]);

  // Переключение состояния дропдауна
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Обработчик клика по варианту
  const handleOptionClick = (option: string) => {
    const isSelected = selectedValues.includes(option);
    const updatedValues = isSelected
      ? selectedValues.filter((item) => item !== option)
      : [...selectedValues, option];
    
    setSelectedValues(updatedValues);
    onSelected(updatedValues); // Передаем обновленные выбранные значения через пропс onSelected
  };

  // Закрытие дропдауна при клике вне его
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.dropdownContainer} ref={dropdownRef}>
      <div className={`${styles.dropdown} ${isDropdownOpen ? styles.open : ''}`} onClick={toggleDropdown}>
        <label
          className={`${styles.label} ${
            selectedValues.length > 0 || isDropdownOpen ? styles.floating : ''
          }`}
        >
          {label}
        </label>
        {selectedValues.length > 0 ? (
          <span className={styles.selectedText}>
            {selectedValues.join(', ')}
          </span>
        ) : (
          <span className={styles.placeholder}></span>
        )}
        <span className={styles.arrow}>
          <ArrowDown isActive={isDropdownOpen} />
        </span>
      </div>
      {isDropdownOpen && (
        <div className={styles.values}>
          {values.map((option, index) => (
            <div
              key={index}
              className={`${styles.option} ${
                selectedValues.includes(option) ? styles.selected : ''
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
          <div className={styles.buttons}>
            <button
              className={styles.deleteButton}
              onClick={() => setSelectedValues([])} // Сбрасываем выбранные значения
            >
              Сбросить
            </button>
            <button
              className={styles.okButton}
              onClick={() => setIsDropdownOpen(false)} // Закрываем дропдаун
            >
              Применить
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
