import { sendPostRequest } from "../../../shared/api";

// Интерфейс для ответа от сервера
interface CarDataResponse {
    data: {
        data: any; // Уточните тип, если данные известны
        cars: any[]; // Укажите правильный тип массива машин
    };
}

// Функция для получения данных автомобиля по `inner_id`
export async function getCarDataById(inner_id: number): Promise<CarDataResponse> {
    const data = await sendPostRequest('exchange/search', {
        mark: '',
        model: '',
        inner_id: inner_id,
        access_token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiKzc5Nzc3MTUxOTczIiwiZXhwIjoxNzI2MzQ5Njk4fQ.Y0nqP54-aNkKfEqh90JSQUcZXNaX2eX_LKE7-sD0a4Y'
    });

    // Приведение типа
    return data as CarDataResponse;
}
