import { CarsListHeader } from "../../../shared/ui/CarsListHeader/ui/CarsListHeader";
import styles from './CarsList.module.css';
import { CarCard } from "../../../entities/CarCard/ui/CarCard";
import { parseDateTime } from "../lib/ParseDateTime";
import React, { useEffect } from "react";
import { Cars } from "../../../common/interfaces";
import { useFavoriteContext } from "../../../contexts/FavoriteContext"; // Импортируем контекст
import { getFavouriteData } from "../../navbar/lib/request"

// Типизация пропсов компонента CarsList
interface CarsListProps {
    cars?: Cars[];
}

export function CarsList({ cars }: CarsListProps) {
    const { favoriteList, setFavoriteList } = useFavoriteContext(); // Используем контекст

    useEffect(() => {
        // Функция для сравнения двух массивов по значению
        const areFavoritesEqual = (newFavorites: any[], currentFavorites: any[]): boolean => {
            return JSON.stringify(newFavorites) === JSON.stringify(currentFavorites);
        };

        // Подгружаем список избранных при первом рендере
        async function fetchFavorites() {
            const response = await getFavouriteData(); // Получаем избранное с сервера
            const newFavorites = response.data.cars || []; // Извлекаем список избранного

            // Обновляем список избранного, только если новый список отличается от текущего
            if (!areFavoritesEqual(newFavorites, favoriteList)) {
                setFavoriteList(newFavorites); // Обновляем контекст только если список изменился
            }
        }

        fetchFavorites();
    }, [favoriteList, setFavoriteList]); // Добавляем зависимость от favoriteList

    console.log('Полученные машины в CarsList:', cars);
    console.log('Избранные автомобили:', favoriteList); // Лог для проверки данных

    // Проверяем, что favoriteList является массивом, прежде чем использовать .some
    const isFavoriteListArray = Array.isArray(favoriteList);

    return (
        <div className={styles.layout}>
            <CarsListHeader />
            {
                (!cars || !Array.isArray(cars) || cars.length === 0) ? (
                    <div className={styles.header_info}>
                        <h1>Автомобилей нет</h1>
                    </div>
                ) : (
                    cars.map(car => {
                        const isFavorite = isFavoriteListArray && favoriteList.some((fav: any) => fav.inner_id === car.inner_id); // Проверяем, в избранном ли автомобиль
                        return (
                            <CarCard 
                                key={car.inner_id}  // Уникальный ключ для каждого элемента
                                title={car.title} 
                                inner_id={car.inner_id} 
                                date={parseDateTime(car.date_added)} 
                                color={car.color} 
                                displacement={car.displacement} 
                                transmission={car.transmission} 
                                price={car.price_eur} 
                                mileage={car.km_age} 
                                car_picture={car.image_urls}  // Обратите внимание на car_picture
                                year={car.year}
                                shop_url={car.url}
                                isFavorite={isFavorite}  // Передаем информацию, избранный ли автомобиль
                            />
                        );
                    })
                )
            }
        </div>
    );
}
