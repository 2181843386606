import { sendPostRequest } from "../../../shared/api";
import { BigCarCard } from "../../../widgets/BigCarCard/ui/BigCarCard";
import { CarsList } from "../../../widgets/CarsList/ui/CarsList";
import { MapStatistics } from "../../../widgets/MapStatistics";
import { Navbar } from "../../../widgets/navbar";
import { getCarDataById } from "../lib/functions";
import styles from './MarketPage.module.css';
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { defaultSelectCities } from "../model/constants";
import { europeanCountriesAndCities, countryCodes, cityTranslations } from "../model/constants"
import { Cars } from "../../../common/interfaces"


export function MarketPage() {
    const { id } = useParams<{ id: string }>(); // Деструктурируем id из useParams с типизацией
    const [car, setCar] = useState<Cars | null>(null); // Устанавливаем тип Car или null для состояния car
    const [carList, setCarList] = useState<Cars[]>([]); // Устанавливаем тип массива машин
    const [selectedRegions, setSelectedRegions] = useState<string[]>([]); // Массив строк для выбранных регионов
    const [selectedCities, setSelectedCities] = useState<string[]>([]); // Массив строк для выбранных городов
    const [regions, setRegions] = useState<string[]>([]); // Массив строк для регионов
    const [cities, setCities] = useState<string[]>(defaultSelectCities); // Массив городов с начальным значением


    function handleCars(cars: any) {
        setCarList(cars.cars)
        setCar(cars.data)
    }

    useEffect(() => {
        async function getData() {
            try {
                const data = await getCarDataById(Number(id));

                // Проверяем, есть ли в ответе данные и структура ожидаемая
                if (data?.data?.data) {
                    setCar(data.data.data); // Устанавливаем машину
                } else {
                    console.error("Ошибка: данные по машине не найдены");
                }

                if (data?.data?.cars) {
                    setCarList(data.data.cars); // Устанавливаем список машин
                } else {
                    console.error("Ошибка: список машин не найден");
                }
            } catch (error) {
                console.error("Ошибка при получении данных:", error);
            }
        }
        if (id) {
            getData();
        }
        
    }, [id]); // Добавляем id как зависимость, чтобы эффект обновлялся при изменении id

    // Обработчик для выбора городов
    function handleSelectedCities(value: string[]) {
        const citiesNew = value.map(reg => reg || reg);
        setSelectedCities(citiesNew);
    }

    // Обработчик для выбора регионов
    function handleSelectRegion(selectedValues: string[]) {
        setSelectedRegions(selectedValues);
        const citiesNew: string[] = [];
        const regNew: string[] = [];

        for (let i = 0; i < selectedValues.length; i++) {
            citiesNew.push(...europeanCountriesAndCities[selectedValues[i]] || []);
            regNew.push(countryCodes[selectedValues[i]] || '');
        }

        const uniqueArray = Array.from(new Set(citiesNew));

        setCities(uniqueArray);
    }

    return (
        <React.Fragment>
            <div className={styles.container}>
                <BigCarCard
                    car={car}
                    handleRegions={handleSelectRegion}
                    handleCities={handleSelectedCities}
                    regions={Object.keys(europeanCountriesAndCities)}
                    selectedRegions={selectedRegions}
                    selectedCities={selectedCities}
                    cities={cities}
                    id={id}
                    handleCars={handleCars}
                />
                <MapStatistics
                    selected={selectedRegions}
                    handleRegions={handleSelectRegion}
                    regions={selectedRegions}
                    cities={cities}
                />
                {carList && <CarsList cars={carList} />}
            </div>
        </React.Fragment>
    );
}
