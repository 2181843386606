import React, { useState, useRef, useEffect } from 'react';
import styles from './Selector.module.css';
import { ArrowDown } from '../../Icons/ArrowDown';
import { SelectorProps } from '../types/Selector.types';
import { useOutsideClick } from '../hooks/useOutsideClick';

export const Selector: React.FC<SelectorProps> = ({
  label,
  values,
  type,
  value,
  onValue,
  width,
  filtersSelector = false,
  canType = false,
  handleName,
  handleSaveFilters,
  isCars,
  
  removeFilters = () => {},
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [typedName, setTypedName] = useState<string>('');
  const [selectedValue, setSelectedValue] = useState<string | undefined>(value || '');
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isTypingName, setIsTypingName] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const typedNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!value) {
      setSelectedValue('');
    } else {
      setSelectedValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (!selectedValue) {
      setTypedName('');
    } else {
      setTypedName(selectedValue);
    }
  }, [selectedValue]);

  const isDisabled = !filtersSelector && values.length === 0;

  const toggleDropdown = () => {
    if (!isDisabled) {
      if(!isDropdownOpen){
        setTypedName('')
        typedNameRef.current?.focus()
      }
      setIsDropdownOpen((prev) => !prev);
      setIsFocused(true);
    }
  };

  const handleValueClick = (selected: string) => {
    setSelectedValue(selected);
    setIsDropdownOpen(false);
    setIsFocused(false);
    onValue(selected);
  };

  useOutsideClick(dropdownRef, () => {
    setIsDropdownOpen(false);
    setTypedName(selectedValue || '');
    setIsFocused(false);
  });

  const handleClearSelection = () => {
    setSelectedValue('');
    console.log('hello'); // Логируем при нажатии на крестик
  };

  return (
    <div className={styles.dropdownContainer} ref={dropdownRef} style={{ width }}>
      <div
        className={`${styles.dropdown} ${isDropdownOpen ? styles.open : ''} ${isDisabled ? styles.disabled : ''}`}
        onClick={toggleDropdown}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        style={
          type === 'left'
            ? {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderRight: 0,
              }
            : type === 'right'
            ? {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }
            : {}
        }
      >
        <span
          className={`${styles.floatingLabel} ${
            selectedValue || isFocused ? styles.floatingLabelActive : ''
          }`}
        >
          {label}
        </span>
        {selectedValue && (
          canType 
          ? 
          <input className={styles.typedNameInput} ref={typedNameRef} type='text'
             value={typedName} onChange={e => setTypedName(e.target.value)} readOnly={!isDropdownOpen}/>
          : 
          <span className={styles.selectedText}>{selectedValue}</span>)
        }
        <span className={styles.arrow}>
          <ArrowDown isActive={isDropdownOpen} />
        </span>
        {selectedValue && !isCars && (
          <span className={styles.clearIcon} onClick={handleClearSelection}>
            &times;
          </span>
        )}
      </div>
      {isDropdownOpen && !isDisabled && (
        <div className={styles.values}>
          {values.filter(e => e.toLowerCase().startsWith(typedName.toLowerCase())).map((item, index) => (
            <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
              <div
                className={`${styles.value} ${
                  selectedValue === item ? styles.selected : ''
                }`}
                onClick={() => handleValueClick(item)}
              >
                {item}
              </div>
            </div>
          ))}
          {filtersSelector && !isTypingName && (
            <button style={{ width: '100%' }} onClick={() => setIsTypingName(true)} className={styles.button}>
              Сохранить фильтр
            </button>
          )}
          {isTypingName && (
            <div className={styles.saveFiltersContainer}>
              <input
                onChange={handleName ? (event) => handleName(event.target.value) : () => {}}
                className={styles.input}
                placeholder='Название'
              />
              <button
                onClick={handleSaveFilters ? () => handleSaveFilters() : () => {}}
                className={styles.button}
              >
                &#10003;
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
