
import styles from './FlyingLogo.module.css'


export function FlyingLogo() {
    return (
        <div className={styles.container}>
            <img src="caraiidark.jpg" className={styles.logo}/>
        </div>
    )
}