import React, { useState } from 'react';
import styles from './AutoNameSelector.module.css';
import { ListOfTypes } from '../../../shared/ui/ListOfTypes/ui/ListOfTypes';
import { ListOfBrands } from '../../../shared/ui/ListOfBrands';
import { SearchButton } from '../../../shared/ui/SearchButton/ui/SearchButton'; // Импортируем новый компонент
import searchIcon from '../../../shared/assets/search_glass.svg'; // Обычные очки


export function AutoNameSelector() {
    const types = ['Все', 'Новые', 'С пробегом'];
    const [selectedType, setSelectedType] = useState('Все');
    return (
        <div className={styles.container}>
            <div className={styles.headerRow}>
                <div className={styles.rowElement}>
                    <ListOfTypes values={types} selected={selectedType} onSelect={setSelectedType} />
                </div>
                {/* <div className={styles.autoSelectButton}><SearchButton icon={searchIcon} label="Подбор авто" redirectPath="/chat" /> Используем новый компонент</div> */}
            </div>
            <ListOfBrands selectedType={selectedType} />
        </div>
    );
}
