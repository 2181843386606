import styles from './CarsListHeader.module.css'
import React from 'react'


export function CarsListHeader() {
    return (
        <div className={styles.container}>
            <div className={`${styles.column} ${styles.toHide}`}>
                <p>Фото</p>
            </div>

            <div className={`${styles.column} ${styles.toHide}`}>
                <p>Дата</p>
            </div>

            <div className={styles.column}>
                <p>Автомобиль</p>
            </div>

            <div className={`${styles.column} ${styles.toHide}`}>
                <p>Год</p>
            </div >

            <div className={styles.column}>
                <p>Цена</p>
            </div>

            <div className={styles.column}>
                <p>Пробег, км</p>
            </div>

            <div className={`${styles.column} ${styles.toHide}`}>
                <p>КПП, объем</p>
            </div>

            <div className={`${styles.column} ${styles.toHide}`}>
                <p>Город</p>
            </div>

            <div className={`${styles.column}`}>
                <p>Кузов, цвет</p>
            </div>
            
        </div>
    )
}