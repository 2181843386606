import React from "react";

export function Stat({onClick}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      style={{'cursor': "pointer"}}
      onClick={onClick}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeWidth="1"
      >
        <g
          stroke="#929293"
          strokeWidth="1.25"
          transform="translate(-1272 -779)"
        >
          <g transform="translate(110 679)">
            <g transform="translate(1163 101)">
              <path d="M0 0v16a1 1 0 001 1h15.328"></path>
            </g>
          </g>
        </g>
      </g>
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeWidth="1"
      >
        <g
          stroke="#929293"
          strokeWidth="1.25"
          transform="translate(-1272 -780)"
        >
          <g transform="translate(110 679)">
            <g transform="translate(1163 101)">
              <path d="M2.833 8.5l2.33 2.33a1 1 0 001.523-.129l1.497-2.11a1 1 0 011.52-.131l.39.387a1 1 0 001.5-.104l5.092-6.685h0"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
