import { Link, Outlet, useNavigate } from 'react-router-dom';
import styles from './Navbar.module.css';
import { ReactComponent as UserIcon } from '../../../shared/assets/profile.svg';
import React, { useEffect, useState, useRef } from 'react';
import { getFavouriteData } from '../lib/request';
import { useFavoriteContext } from '../../../contexts/FavoriteContext';

const Image = React.memo(function Image({ src, width, onClick }) {
    return (
        <div className={styles.menu_logo} onClick={onClick}>
            <img src={src} width={width} alt="Logo" />
        </div>
    );
});

export function Navbar() {
    const accessToken = localStorage.getItem('accessToken');
    const navigate = useNavigate();
    const { setFavoriteList, favoriteList } = useFavoriteContext();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const menuRef = useRef(null);
    const [loading, setLoading] = useState(true);
    const [favoriteCount, setFavoriteCount] = useState(0);  // Сохраняем текущее количество избранного

    // Загружаем данные избранного только один раз при загрузке компонента
    useEffect(() => {
        async function getData() {
            if (accessToken) {
                setLoading(true); // Начинаем загрузку
                const favouriteData = await getFavouriteData();
                setFavoriteList(favouriteData.data.cars || []);
                
            }
            else {
                setFavoriteList([]);
            }
            setLoading(false); // Заканчиваем загрузку
        }
        getData();  // Вызов только один раз при загрузке компонента
    }, [accessToken, setFavoriteList]); // Зависимость только от accessToken и setFavoriteList

    // Обновляем только если количество избранного изменилось
    useEffect(() => {
        if (favoriteList.length !== favoriteCount) {
            setFavoriteCount(favoriteList.length);
        }
    }, [favoriteList, favoriteCount]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsMenuOpen(false);
                setIsSelected(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleLogoClick = () => {
        navigate('/');
    };

    const toggleMenu = () => {
        setIsMenuOpen((prevState) => !prevState);
        setIsSelected((prevState) => !prevState);
    };

    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        navigate('/');
    };

    return (
        <div>
            <div className={styles.container}>
                <div className={styles.menu}>
                    <Image width="90px" src="caraii.jpg" onClick={handleLogoClick} />
                    <Link className={styles.menu_point + (window.location.pathname === '/' ? ' ' + styles.active : '')} to={'/'}>
                        Главная
                    </Link>
                    <Link className={styles.menu_point + (window.location.pathname === '/search' ? ' ' + styles.active : '')} to={'/search'}>
                        Поиск
                    </Link>
                    <Link className={styles.menu_point + (window.location.pathname === '/favourites' ? ' ' + styles.active : '')} to={'/favourites'}>
                        Избранное
                        {loading ? (
                            <span className={styles.favouriteCount}>Загрузка...</span>
                        ) : (
                            <span className={styles.favouriteCount}>{favoriteCount}</span>
                        )}
                    </Link>
                    <Link className={styles.menu_point + (window.location.pathname === '/market' ? ' ' + styles.active : '')} to={'/market'}>
                        Рынок
                    </Link>
                    <Link className={styles.menu_point + (window.location.pathname === '/api' ? ' ' + styles.active : '') + ' ' + styles.menu_point_market} to={'/api'}>
                        API
                    </Link>
                    {/* <Link className={styles.menu_point + (window.location.pathname === '/new-ad' ? ' ' + styles.active : '')} to={'/new-ad'}>
                        Создать объявление
                    </Link> */}
                </div>
                <div className={styles.user}>
                    {accessToken ? (
                        <div onClick={toggleMenu} className={`${styles.userMenuTrigger} ${isSelected ? styles.selected : ''}`}>
                            <UserIcon />
                            <p className={styles.user_name}>CarPassUser</p>
                            {isMenuOpen && (
                                <div className={styles.dropdownMenu} ref={menuRef}>
                                    <Link to="/profile" className={styles.dropdownItem}>
                                        Профиль
                                    </Link>
                                    <Link to="/subscription" className={styles.dropdownItem}>
                                        Подписка
                                    </Link>
                                    <button onClick={handleLogout} className={styles.dropdownItem + ' ' +  styles.exitButton}>
                                        Выйти
                                    </button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <Link to="/login" className={styles.login_button}>
                            Войти
                        </Link>
                    )}
                </div>
            </div>
            <Outlet />
        </div>
    );
}
