import React from 'react';
import { MainPageProvider } from '../../../contexts/MainPageContext'; // Добавили провайдер контекста
import { AutoNameSelector } from '../../../widgets/AutoNameSelector';
import { CarShowcase } from '../../../widgets/CarShowcase';
import styles from './MainPage.module.css';

export function MainPage() {
    return (
        <MainPageProvider> {/* Оборачиваем компоненты в провайдер */}
            <div className={styles.container}>
                <AutoNameSelector />
                <CarShowcase />
            </div>
        </MainPageProvider>
    );
}
