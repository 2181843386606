import React from 'react';

interface SedanProps {
  color?: string;
}

export function Sedan({ color = '#000000' }: SedanProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="21"
      viewBox="0 0 121 43"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={color} transform="translate(-322 -123)">
          <path
            d="M344.714 146.646a9.5 9.5 0 110 19 9.5 9.5 0 010-19zm71 0a9.5 9.5 0 110 19 9.5 9.5 0 010-19zm12.684-14.694c7.744.126 11.616.455 11.616.987v10.136c1.919.46 2.878 1.171 2.878 2.131 0 1.44-.77 9.867-2.878 9.867-1.014 0-3.639.867-11.616 1.768-.213.024-.449.048-.708.073.016-.254.024-.51.024-.768 0-6.628-5.372-12-12-12-6.627 0-12 5.372-12 12 0 .574.04 1.139.119 1.692l-47.35.662c.152-.761.231-1.548.231-2.354 0-6.628-5.372-12-12-12-6.627 0-12 5.372-12 12 0 .896.099 1.77.285 2.61l-7.865-.49c-1.683-.515-2.525-1.222-2.525-2.12v-3.583c-.812-1.717-.812-3.454 0-5.211 1.218-2.637 1.792-5.681 4.024-6.278 2.23-.597 12.273-4.255 24.206-5.634 10.92-7.206 19.78-11.104 26.582-11.696 10.202-.887 32.328-2.939 50.977 8.208z"
            transform="matrix(-1 0 0 1 764.892 0)"
          ></path>
        </g>
      </g>
    </svg>
  );
}
