import React from 'react';

interface ArrowDownProps {
  isActive: boolean;
}

export function ArrowDown({ isActive }: ArrowDownProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 32 32"
      style={
        isActive
          ? { transform: 'rotate(180deg)', transition: 'all 0.3s', opacity: 0.7, scale: '0.8' }
          : { transition: 'all 0.3s', opacity: 0.5, scale: '0.8' }
      }
    >
      <path d="M.256 8.606a1.063 1.063 0 011.813-.75l14.119 14.119L30.101 8.063c.413-.412 1.087-.412 1.5 0s.413 1.088 0 1.5L16.938 24.232a1.063 1.063 0 01-1.5 0L.569 9.363a1.053 1.053 0 01-.313-.756z" />
    </svg>
  );
}
