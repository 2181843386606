import { useEffect, useState } from 'react'
import { MultiSelect } from '../../../shared/ui/MultiSelect'
import { Sedan } from '../../../shared/ui/Sedan/ui/Sedan'
import { Selector } from '../../../shared/ui/Selector'
import { getBrandsList, getModelsList } from '../lib/functions'
import { Input } from "../../../shared/ui/input/ui/Input";
import { Button } from "../../../shared/ui/button/ui/Button";
import styles from './CarFiltersForMarket.module.css'

export const countryCodes = {
    'Германия': 'de',
    'Франция': 'fr',
    'Италия': 'it',
    'Испания': 'es',
    'Великобритания': 'gb',
    'Польша': 'pl',
    'Нидерланды': 'nl',
    'Чехия': 'cz',
    'Австрия': 'at',
    'Швеция': 'se'
};

export function CarFiltersForMarket( {handleRegions, cities, regions, handleCities, selectedRegions, selectedCities, handleCars, id, car}) {


    const [regionsList, setRegionsList] = useState()
    const [selectedReg, setSelectedReg] = useState(selectedRegions)
    const [citiesList, setCitiesList] = useState()
    const [radius, setRadius] = useState()
    const [offers, setOffers] = useState()
    const [selectedBrand, setSelectedBrand] = useState()
    const [selectedModel, setSelectedModel] = useState()
    const [brandsList, setBrandsList] = useState([])
    const [modelsList, setModelsList] = useState([])
    const [selectGeneration, setSelectGeneration] = useState([''])
    const carCondition = 'Все'

    const countriesAndCodes = {
      "Andorra": "AD",
      "United Arab Emirates": "AE",
      "Albania": "AL",
      "Bosnia and Herzegovina": "BA",
      "Bulgaria": "BG",
      "Czech Republic": "CZ",
      "Germany": "DE",
      "Estonia": "EE",
      "Spain": "ES",
      "Finland": "FI",
      "France": "FR",
      "Greece": "GR",
      "Croatia": "HR",
      "Hungary": "HU",
      "Italy": "IT",
      "South Korea": "KR",
      "Lebanon": "LB",
      "Lithuania": "LT",
      "Latvia": "LV",
      "Monaco": "MC",
      "Montenegro": "ME",
      "North Macedonia": "MK",
      "Serbia": "RS",
      "Sweden": "SE",
      "Slovenia": "SI",
      "Slovakia": "SK",
      "Turkey": "TR",
      "Ukraine": "UA",
      "United States": "US"
    }

    async function findCars() {
        const data = await fetch('https://pinmap.crypferry.com/v1/exchange/search', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
              },
            body: JSON.stringify({
                "access_token": localStorage.getItem('accessToken'),
                "mark": selectedBrand ? selectedBrand : '',
                "region": selectedReg.map(reg => countriesAndCodes[reg]),
                "model": selectedModel,
                "inner_id": id ? id : undefined,
                "model": selectedModel ? selectedModel : ''
              })
        })
        const cars = await data.json()
        handleCars(cars.data)
    }

    useEffect(() => {setSelectedReg(selectedRegions)}, [selectedRegions])

    const radiusOptions = ['10', '20', '30', '40']
    const offersOptions = ['Актуальные и снятые за 2 мес.']

    useEffect(() => {
        async function fetchBrands() {
        
          const brands = await getBrandsList({ carCondition: carCondition });
          setBrandsList(brands);
        }
        fetchBrands();
      }, []);
    
    
      useEffect(() => {
        async function fetchModels() {
          console.log("Обновление списка моделей");
          if (selectedBrand) {
            setSelectedModel(''); // Обнуляем выбранную модель при изменении бренда
            const models = await getModelsList({ mark: selectedBrand, carCondition: carCondition });
            setModelsList(models);
          } else {
            console.log("Марка не выбрана, список моделей очищен");
            setModelsList([]);
          }
        }
        fetchModels();
      }, [selectedBrand]);
    
      return (
        <div className={styles.layout}>
            <div className={styles.filtersContainer}>
                <div className={styles.row}>
                    {/* Блок для выбора марки */}
                    <div className={styles.rowElement}>
                        <Selector
                            label="Марка"
                            values={brandsList.map((brand) => brand.mark)}
                            value={selectedBrand}
                            onValue={setSelectedBrand} // Добавляем onValue для модели
                            width="100%"
                        />
                    </div>

                    {/* Блок для выбора модели */}
                    <div className={styles.rowElement}>
                        <Selector
                            label="Модель"
                            values={modelsList.map((model) => model.model)}
                            value={selectedModel}
                            onValue={setSelectedModel} // Добавляем onValue для модели
                            width="100%"
                            disabled={!selectedBrand}
                        />
                    </div>

                    {/* Блок для выбора поколения */}
                    <div className={styles.rowElementGap}>
                        {selectGeneration.map((gen, index) => (
                            <div key={index} style={{ display: 'flex', width: '100%' }}>
                                <Selector
                                    label="Поколение"
                                    values={[]} // Дополнить доступными поколениями, если они есть
                                    value={gen}
                                    onValue={(selectedGen) =>
                                        setSelectGeneration((prev) => {
                                            const newGenerations = [...prev];
                                            newGenerations[index] = selectedGen;
                                            return newGenerations;
                                        })
                                    }
                                    width="100%"
                                />
                                <Input placeholder="Прим GTI" />
                            </div>
                        ))}
                    </div>
                </div>

                <div className={styles.secondGroup}>
                    <MultiSelect label="Регионы" selected={selectedRegions} onSelected={handleRegions} values={regions} />
                    <MultiSelect label="Города / ZIP" selected={selectedCities} onSelected={handleCities} values={cities} />
                    <Selector label="Радиус" values={radiusOptions} />
                    <Selector label="Предложения" values={offersOptions} />
                </div>

        <div className={styles.buttonContainer}>
          <div className={styles.button}>
            <Button onClick={findCars} width='100%'>Показать Предложения</Button>
          </div>
         </div>
         </div>
         
        </div>
    )
}