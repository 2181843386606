
export async function getBrandsList({ carCondition }) {
    const cachedCarsMarks = localStorage.getItem('carsMarksList');

    if (!cachedCarsMarks) {
        console.error("Данные о марках машин не найдены в кэше");
        return [];
    }

    const carsMarksList = JSON.parse(cachedCarsMarks);

    const result = {};

    carsMarksList.forEach((car) => {
        const mark = car.mark;
        let carCount = 0;

        if (carCondition === 'Все') {
            carCount = car.new_cars_count + car.used_cars_count;
        } else if (carCondition === 'Новые') {
            carCount = car.new_cars_count;
        } else if (carCondition === 'С пробегом') {
            carCount = car.used_cars_count;
        }

        if (carCount === 0) return;

        if (result[mark]) {
            result[mark] += carCount;
        } else {
            result[mark] = carCount;
        }
    });

    const brandsList = Object.entries(result).map(([mark, totalCount]) => ({
        mark,
        totalCount,
    }));

    return brandsList;
}

export async function getModelsList({ mark, carCondition }) {
    const cachedCarsMarks = localStorage.getItem('carsMarksList');

    if (!cachedCarsMarks) {
        console.error("Данные о марках машин не найдены в кэше");
        return [];
    }

    const carsMarksList = JSON.parse(cachedCarsMarks);

    const result = {};

    carsMarksList.forEach((car) => {
        if (car.mark !== mark) return;

        const model = car.model;
        let carCount = 0;

        if (carCondition === 'Все') {
            carCount = car.new_cars_count + car.used_cars_count;
        } else if (carCondition === 'Новые') {
            carCount = car.new_cars_count;
        } else if (carCondition === 'С пробегом') {
            carCount = car.used_cars_count;
        }

        if (carCount === 0) return;

        if (result[model]) {
            result[model] += carCount;
        } else {
            result[model] = carCount;
        }
    });

    const modelsList = Object.entries(result).map(([model, totalCount]) => ({
        model,
        totalCount,
    }));

    return modelsList;
}