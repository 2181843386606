import React, { useState } from 'react';
import styles from './SmallCarCard.module.css';
import defaultCarImage from '../../../shared/assets/default_car_icon.png';

interface Car {
  id: number;
  mark: string;
  model: string;
  year: number;
  price_eur: number;
  km_age: number;
  image_urls: string;
  url: string;
}

interface SmallCarCardProps {
  car: Car;
}

export const SmallCarCard: React.FC<SmallCarCardProps> = ({ car }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false); // Указываем тип состояния как boolean
  let parsedImageUrls: Array<{ url: string }> = [];

  try {
    parsedImageUrls = typeof car.image_urls === 'string' ? JSON.parse(car.image_urls) : [];
  } catch (error) {
    console.error('Ошибка при парсинге image_urls:', error);
    parsedImageUrls = [];
  }

  const carImage = parsedImageUrls.length > 0 && parsedImageUrls[0].url ? parsedImageUrls[0].url : defaultCarImage;

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  const handleCardClick = () => {
    window.open(car.url, "_blank");
  };
  
  return (
    <div className={styles.carCard} onClick={handleCardClick} style={{ cursor: 'pointer' }}>
      <div className={`${styles.carImageWrapper} ${isLoaded ? styles.loaded : ''}`}>
        <img
          src={carImage}
          alt={car.model}
          className={styles.carImage}
          onLoad={handleImageLoad}
        />
      </div>
      <div className={styles.carInfo}>
        <div className={styles.carPrice}>{car.price_eur} €</div>
        <div className={styles.carModel}>{car.mark} {car.model}</div>
        <div className={styles.carDetails}>{car.year} / {car.km_age === 0 ? 'Новый' : `${car.km_age} км`}</div>
      </div>
    </div>
  );
};
