interface Car {
    mark: string;
    model: string;
    new_cars_count: number;
    used_cars_count: number;
}

interface Brand {
    mark: string;
    totalCount: number;
}

interface Model {
    model: string;
    totalCount: number;
}

interface GetBrandsListParams {
    carCondition: string;
}

interface GetModelsListParams {
    mark: string;
    carCondition: string;
}

// Функция для получения списка марок машин
export async function getBrandsList({ carCondition }: GetBrandsListParams): Promise<Brand[]> {
    const cachedCarsMarks = localStorage.getItem('carsMarksList');

    if (!cachedCarsMarks) {
        console.error("Данные о марках машин не найдены в кэше");
        return [];
    }

    const carsMarksList: Car[] = JSON.parse(cachedCarsMarks);

    const result: Record<string, number> = {};

    carsMarksList.forEach((car: Car) => {
        const mark = car.mark;
        let carCount = 0;

        if (carCondition === 'Все') {
            carCount = car.new_cars_count + car.used_cars_count;
        } else if (carCondition === 'Новые') {
            carCount = car.new_cars_count;
        } else if (carCondition === 'С пробегом') {
            carCount = car.used_cars_count;
        }

        if (carCount === 0) return;

        if (result[mark]) {
            result[mark] += carCount;
        } else {
            result[mark] = carCount;
        }
    });

    const brandsList: Brand[] = Object.entries(result).map(([mark, totalCount]) => ({
        mark,
        totalCount,
    }));

    return brandsList;
}

// Функция для получения списка моделей машин
export async function getModelsList({ mark, carCondition }: GetModelsListParams): Promise<Model[]> {
    const cachedCarsMarks = localStorage.getItem('carsMarksList');

    if (!cachedCarsMarks) {
        console.error("Данные о марках машин не найдены в кэше");
        return [];
    }

    const carsMarksList: Car[] = JSON.parse(cachedCarsMarks);

    const result: Record<string, number> = {};

    carsMarksList.forEach((car: Car) => {
        if (car.mark !== mark) return;

        const model = car.model;
        let carCount = 0;

        if (carCondition === 'Все') {
            carCount = car.new_cars_count + car.used_cars_count;
        } else if (carCondition === 'Новые') {
            carCount = car.new_cars_count;
        } else if (carCondition === 'С пробегом') {
            carCount = car.used_cars_count;
        }

        if (carCount === 0) return;

        if (result[model]) {
            result[model] += carCount;
        } else {
            result[model] = carCount;
        }
    });

    const modelsList: Model[] = Object.entries(result).map(([model, totalCount]) => ({
        model,
        totalCount,
    }));

    return modelsList;
}
