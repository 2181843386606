import styles from './Checkbox.module.css';
import React from 'react';

interface CheckboxProps {
    id: string;
    label: string;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    htmlFor: string; // Добавляем htmlFor в пропсы
}

export const Checkbox: React.FC<CheckboxProps> = ({ id, label, onChange, htmlFor }) => {
    return (
        <div className={styles.container}>
            <input onChange={onChange} className={styles.checkbox} type="checkbox" id={id} />
            <label className={styles.label} htmlFor={htmlFor}>{label}</label> {/* Используем htmlFor */}
        </div>
    );
};