import styles from './CarCard.module.css';
import { Sedan } from '../../../shared/ui/Sedan/ui/Sedan';
import { Heart } from '../../../shared/ui/Icons/Heart/ui/Heart';
import { Stat } from '../../../shared/ui/Icons/Stat';
import React, { useState, useEffect } from 'react';
import { sendPatchRequest, sendDeleteRequest } from '../../../shared/api/lib/requests'; 
import { useNavigate } from 'react-router-dom';
import { colors } from '../model/constants';
import { CarCardProps } from '../model/interfaces';

export function CarCard({ date, title, displacement, transmission, price, mileage, inner_id, color, car_picture, year, shop_url, isFavorite }: CarCardProps) {
    const [isFav, setIsFav] = useState(isFavorite); // Устанавливаем начальное состояние по пропсу isFavorite
    const [isLoaded, setIsLoaded] = useState<boolean>(false); // Для отслеживания загрузки изображения
    const navigate = useNavigate();

    useEffect(() => {
        setIsFav(isFavorite); // Обновляем состояние, если передаются изменения в isFavorite
    }, [isFavorite]);

    // Парсинг JSON строки с URL изображениями
    const getFirstImage = () => {
        try {
            const parsedImages = JSON.parse(car_picture); 
            if (Array.isArray(parsedImages) && parsedImages.length > 0) {
                return parsedImages[0].url; 
            }
        } catch (error) {
            console.error("Ошибка парсинга изображения:", error);
        }
        return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-pKl40cUP8JzODO9dFP5U9KuTSQg1sr20dw&s'; // Фолбэк, если изображение отсутствует
    };

    const imageUrl = getFirstImage();

    async function setFavourite() {
        try {
            const data = await sendPatchRequest('cars-system/favourite', {
                "access_token": localStorage.getItem('accessToken'),
                "inner_id": inner_id
            });
            if (data) {
                setIsFav(true);
            }
        } catch (error) {
            console.error('Error setting favourite:', error);
        }
    }

    async function deleteFavourite() {
        try {
            const data = await sendDeleteRequest('cars-system/favourite', {
                "access_token": localStorage.getItem('accessToken'),
                "inner_id": inner_id
            });
            if (data) {
                setIsFav(false);
            }
        } catch (error) {
            console.error('Error deleting favourite:', error);
        }
    }

    async function handleFavourite(event: React.MouseEvent<HTMLDivElement>) {
        event.stopPropagation(); // Останавливаем всплытие, чтобы не было перехода на страницу
        if (isFav) {
            await deleteFavourite();
        } else {
            await setFavourite();
        }
    }

    const handleImageLoad = () => {
        setIsLoaded(true); // Устанавливаем, что изображение загружено
    };

    return (
        <>
        <div 
            className={`${styles.container} ${styles.toHide}`} 
            onClick={() => window.open(shop_url, '_blank')} // Переход по ссылке магазина
        >
            <div className={`${styles.carImageWrapper} ${styles.toHide} ${isLoaded ? styles.loaded : ''}`}>
                <img 
                    className={`${styles.carImage} ${isLoaded ? styles.loaded : ''}`}
                    src={imageUrl} 
                    alt='carPic' 
                    onLoad={handleImageLoad}
                />
            </div>
            <div className={styles.toHide}>
                <p className={styles.boldText}>{date.time}</p>
                <p className={styles.smallText}>{date.day} {date.month}</p>
            </div>

            <div>
                <p className={styles.carNameText}>{title}</p>
            </div>

            <div className={styles.toHide}>
                <p className={styles.boldText}>{year}</p>
            </div>

            <div>
                <p className={styles.boldText}>€{price}</p>
            </div>

            <div>
                <p className={styles.boldText}>{mileage}</p>
            </div>

            <div className={styles.toHide}>
                <p className={styles.boldText}>{transmission}</p>
                <p className={styles.smallText}>{displacement}</p>
            </div>

            <div className={styles.toHide}>
                <p className={styles.boldText}>Берлин</p>
            </div>

            <div className={styles.icons}>
                <div className={styles.toHide}>
                  <Sedan color={colors[color]} />
                </div>
                
                <Heart 
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => handleFavourite(e)} 
                    isFavourite={isFav} 
                />
                <Stat 
                    onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                        e.stopPropagation(); // Останавливаем всплытие
                        navigate(`/market/${inner_id}`); // Переход на страницу автомобиля
                    }} 
                />
            </div>
        </div>
            
        <div 
            className={styles.containerMobile} 
            onClick={() => window.open(shop_url, '_blank')} // Переход по ссылке магазина
        >
            
                <img 
                    className={`${styles.carImageMobile} ${isLoaded ? styles.loaded : ''}`}
                    src={imageUrl} 
                    alt='carPic' 
                    onLoad={handleImageLoad}
                />
      
                
            <div className={styles.infoMobile}>
                <div>
                        <p className={styles.carNameTextMobile}>{title}</p>            
                </div>
                <div className={styles.fullInfoMobile}>
                    {
                        year  && <p className={styles.boldText}>  {year} г. </p>
                    }
                   
                    <p className={styles.boldText}>Пробег: {mileage} км</p>
                </div>
                <div className={styles.priceContainerMobile}>
                    <p className={styles.priceMobile}>€{price}</p>
                    <div style={{display: 'flex', gap: '10px'}}>
                        <Heart 
                        onClick={(e: React.MouseEvent<HTMLDivElement>) => handleFavourite(e)} 
                        isFavourite={isFav} 
                        />
                        <Stat 
                            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                                e.stopPropagation(); // Останавливаем всплытие
                                navigate(`/market/${inner_id}`); // Переход на страницу автомобиля
                            }} 
                        />
                    </div>
                </div>
            </div>

        </div>

        
        </>
    );
}
