import MapChart from "../../../shared/ui/Map/map"
import styles from './MapStatistics.module.css'
import { MarketRegionsHeader } from "../../../shared/ui/MarketRegionsHeader"
import { RegionsAccordeon } from "../../RegionsAccordeon"
import { MainPageContext } from "../../../contexts/MainPageContext"
import { useContext, useEffect, useState } from "react"

export function MapStatistics({handleRegions, regions, selected}) {
    const [adresses, setAdresses] = useState()
    const [cities, setCities] = useState()
    const [countries, setCountries] = useState()
    const {mainPageData} = useContext(MainPageContext)
    
    useEffect(() => {
        if (mainPageData) {
            let adr = mainPageData.data.adresses.data
            setAdresses(adr)
        }
        
    }, [mainPageData])

    return (
        <div className={styles.container}>
            <MapChart adresses={adresses} onChangeCountries={handleRegions} selected={selected}/>
            <div className={styles.content}>   
                {/* <MarketRegionsHeader /> */}
                {/* <RegionsAccordeon/> */}
            </div>
        </div>
    )
}