import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { getCarsMarksList, getMainPageData } from '../widgets/CarShowcase/lib/request';
import { MainPageResponse, MainPageContextProps } from '../common/interfaces';


const defaultContext: MainPageContextProps = {
  mainPageData: null,
  loading: true,
  error: null,
};

// Создаем контекст
export const MainPageContext = createContext<MainPageContextProps>(defaultContext);

// Типизируем пропсы провайдера
interface MainPageProviderProps {
  children: ReactNode;
}

export const MainPageProvider: React.FC<MainPageProviderProps> = ({ children }) => {
  const [mainPageData, setMainPageData] = useState<MainPageResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [allAddreses, setAllAdresses] = useState()
  const [cities, setCities] = useState()
  const [countries, setCountries] = useState()

  // Фоновый запрос марок машин
  const fetchCarsMarksListInBackground = async (): Promise<void> => {
    try {
      // const cachedCarsMarks = localStorage.getItem('carsMarksList');
      // if (!cachedCarsMarks) {
        const response = await getCarsMarksList();

        console.log("response eee", response.data.models)
        
        if (response && Array.isArray(response.data.models)) {
          const carsMarksList = response.data.models;
          localStorage.setItem('carsMarksList', JSON.stringify(carsMarksList));
        } else {
          console.error('Ошибка: данные о марках машин не получены или неверны.');
        }
      // }
    } catch (error: any) {
      console.error('Ошибка при загрузке марок машин:', error);
    }
  };

  useEffect(() => {

     
      const fetchData = async (): Promise<void> => {
        try {
          const data = await getMainPageData();
          if (data) {
            setMainPageData(data);
            localStorage.setItem('mainPageData', JSON.stringify(data));
  
            console.log('FIOHWIFHWOFHUWHOHWOUF')
            console.log(data)
          } else {
            setError('Данные главной страницы не получены.');
          }
        } catch (error: any) {
          setError('Ошибка при загрузке данных главной страницы');
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    

    // Запуск фоновой загрузки марок машин
    fetchCarsMarksListInBackground();
  }, []);

  return (
    <MainPageContext.Provider value={{ mainPageData, loading, error }}>
      {children}
    </MainPageContext.Provider>
  );
};
