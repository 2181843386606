import { useEffect, useState } from "react";
import styles from './RegistrationSteps.module.css';
import { Button } from "../../../shared/ui/button";
import { useNavigate } from "react-router-dom";
import { InputWithButton } from "../../../shared/ui/InputWithButton/ui/InputWithButton";
import { sendPostRequest } from "../../../shared/api";

// Компонент для отображения заголовка на каждой стадии
function LoginHeader({ stage }) {
  const [text, setText] = useState('');

  useEffect(() => {
    switch (stage) {
      case 'phone':
        setText('Вход в CarAii');
        break;
      case 'phoneCode':
        setText('Введите код');
        break;
      case 'end':
        setText('Успешный вход');
        break;
      default:
        setText('');
    }
  }, [stage]);

  return <h1 className={styles.headerText}>{text}</h1>;
}

// Основной компонент для отображения шагов регистрации
export function RegistrationSteps() {
  const [stage, setStage] = useState('phone'); // текущая стадия ('phone', 'phoneCode', 'end')
  const [signKey, setSignKey] = useState(''); // ключ для подтверждения после отправки номера
  const [phone, setPhone] = useState(''); // введённый номер телефона
  const [code, setCode] = useState(''); // введённый код подтверждения
  const [errorMessage, setErrorMessage] = useState(''); // состояние для отображения ошибки
  const navigate = useNavigate(); // навигация для перехода на другие страницы

  // Отправляем запрос с номером телефона для авторизации
  async function handlePhoneAuth() {
    try {
      const data = await sendPostRequest('auth/login', {
        phone: phone,
        send_message_type: "WhatsApp" // тип отправки сообщения (например, через WhatsApp)
      });
      
      // Переход на стадию подтверждения кода
      setStage('phoneCode');
      setSignKey(data?.data?.sign_key); // сохраняем ключ для подтверждения
    } catch (error) {
      console.error('Ошибка при отправке номера телефона:', error);
      setErrorMessage('Ошибка при отправке номера. Попробуйте еще раз.');
    }
  }

  // Отправляем запрос с кодом для подтверждения входа
  async function handlePhoneSubmit() {
    if (!code) {
      setErrorMessage('Введите код подтверждения');
      return;
    }

    try {
      const data = await sendPostRequest('auth/confirm-login', {
        sign_key: signKey,
        verify_code: code
      });

      if (data?.exception === 666) {
        // Если код не подошел, показываем ошибку
        setErrorMessage(data?.message || "Код верификации не подходит");
      } else {
        // Если успешный вход, сохраняем токен и переходим на следующую стадию
        localStorage.setItem('accessToken', data?.data['access_token']);
        setStage('end');
      }
    } catch (error) {
      console.error('Ошибка при подтверждении кода:', error);
      setErrorMessage('Ошибка при подтверждении кода. Попробуйте еще раз.');
    }
  }

  return (
    <div className={styles.wrapper}>
      {/* Отображаем шаги регистрации, пока стадия не 'end' */}
      {stage !== 'end' ? (
        <div className={styles.container}>
          {/* Отображение прогресса шагов */}
          <div className={styles.progress}>
            <div className={`${styles.step} ${stage === 'phone' && styles.currentStep}`}></div>
            <div className={`${styles.step} ${stage === 'phoneCode' && styles.currentStep}`}></div>
            <div className={`${styles.step} ${stage === 'end' && styles.currentStep}`}></div>
          </div>
          {/* Заголовок в зависимости от стадии */}
          <LoginHeader stage={stage} />
          <div className={styles.content}>
            {/* Форма для ввода номера телефона */}
            {stage === 'phone' ? (
              <InputWithButton
                placeholder="Номер телефона"
                buttonText="Продолжить"
                onChange={setPhone}
                value={phone}
                isPhoneInput={true} // флаг для специального формата ввода номера
                onSubmit={handlePhoneAuth}
              />
            ) : (
              // Форма для ввода кода подтверждения
              stage === 'phoneCode' && (
                <>
                  <div className={styles.phoneNumberContainer}>
                    <span>Введённый номер: +7-({phone.slice(1, 4)})-{phone.slice(4, 7)}-{phone.slice(7,9)}-{phone.slice(9, 12)}</span>
                    <button onClick={() => setStage('phone')} className={styles.changeButton}>
                      Изменить
                    </button>
                  </div>
                  <InputWithButton
                    onChange={setCode}
                    value={code}
                    placeholder="Код подтверждения"
                    buttonText="Войти"
                    onSubmit={handlePhoneSubmit}
                  />
                  {/* Отображение ошибки при некорректном коде */}
                  {errorMessage && (
                    <div className={styles.errorMessage}>
                      {errorMessage}
                    </div>
                  )}
                </>
              )
            )}
          </div>
        </div>
      ) : (
        // Стадия успешного входа
        <div className={styles.container}>
          <div className={styles.endRegistration}>
            <LoginHeader stage={stage} />
            <Button onClick={() => navigate('/')} className={styles.endButton}>
              Перейти на сайт
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
