import React, { useState, useEffect } from 'react';
import { CarFilters } from '../../../features/СarFilters';
import { CarsList } from '../../../widgets/CarsList/ui/CarsList';
import styles from './OffersPage.module.css';
import { Cars } from "../../../common/interfaces"; // Импортируем правильный интерфейс
import { getCars } from '../../../features/СarFilters/lib/request';

export function OffersPage() {
    const [cars, setCars] = useState<Cars[]>([]); // Состояние для хранения машин
    const [loading, setLoading] = useState<boolean>(false); // Состояние для индикатора загрузки

    // Загружаем данные из localStorage при первом рендере
    useEffect(() => {
        const cachedCars = localStorage.getItem('cachedCars');
        if (cachedCars) {
            const parsedCars = JSON.parse(cachedCars);
            if (Array.isArray(parsedCars)) {
                setCars(parsedCars); // Подгружаем машины из кэша
                console.log('Машины загружены из localStorage:', parsedCars);
            }
        }
    }, []); // Пустой массив зависимостей означает, что эффект выполнится один раз при монтировании компонента

    // Преобразуем переданные данные в массив автомобилей

    const handleCarsSearch = async (cars: any) => {
        console.log("handleCarsSearch вызвана с данными:", cars.cars); // Лог перед запросом
        setLoading(true); // Включаем индикатор загрузки
        try {
            if (Array.isArray(cars.cars)) {
                setCars(cars.cars); // Обновляем состояние напрямую с массивом автомобилей
                localStorage.setItem('cachedCars', JSON.stringify(cars.cars)); // Сохраняем в localStorage
                console.log("Состояние cars обновлено и сохранено в localStorage:", cars.cars.length, "машин найдено");
            } else {
                console.log("Ответ не является массивом или пуст:", cars.cars);
                setCars([]); // Если что-то пошло не так, сбрасываем состояние машин
                localStorage.removeItem('cachedCars'); // Очищаем localStorage, если данные некорректны
            }
        } catch (error) {
            console.error('Ошибка при поиске автомобилей:', error);
        } finally {
            setLoading(false); // Отключаем индикатор загрузки
            console.log("Индикатор загрузки отключен");
        }
    };

    useEffect(() => {
        async function getTempCars() {
            let saved = JSON.parse(localStorage.getItem('tempFilters') || '{}')
            const data = await getCars(saved)
            console.log('firs LAUNCH', data)
            await handleCarsSearch(data)
        
        }
        getTempCars()
        
    }, [])
    // Преобразуем переданные данные в массив автомобилей
    

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.content}>
                    {/* Передаем функцию handleCarsSearch в CarFilters */}
                    <CarFilters handleCars={handleCarsSearch} />
                    
                    {/* Отображаем индикатор загрузки или список автомобилей */}
                    {loading ? (
                        <div>Загрузка...</div> // Индикатор загрузки
                    ) : (
                        <>
                            <CarsList cars={cars} /> {/* Список автомобилей */}
                        </>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
}
