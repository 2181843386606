import styles from './ApiPage.module.css'
import React from 'react'


export const ApiPage = () => {
    return (
        <div className={styles.layout}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.header_info}>
                        <h1>API</h1>
                    </div>
                    <div className={styles.header_info}>API carpass временно не доступна. Мы зафиксировали твое желание воспользоваться api и вернемся с ответом совсем скоро!</div>
                </div>  
            </div>
        </div>
    )
}
