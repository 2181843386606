
export const colors: Record<string, string> = {
    blue: '#0000FF',
    red: '#FF0000',
    green: '#00FF00',
    yellow: '#FFFF00',
    black: '#000000',
    white: '#FFFFFF',
    purple: '#800080',
    orange: '#FFA500',
    pink: '#FFC0CB',
    gray: '#808080',
  };